import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TAccount, ICustomerDetails } from 'Models/ResponseModels/Equitas'

type TCbsFields =
  | 'customerFirstName'
  | 'customerMiddleName'
  | 'customerLastName'
  | 'customerEmail'
  | 'customerMobile'
  | 'customerDob'
  | 'customerAddress1'
  | 'customerAddress2'
  | 'customerPincode'
  | 'customerPermanentPincode'
type TPaymentMode = 'PG' | 'DirectDebit'

interface IState {
  accounts?: TAccount[]
  ucic?: string
  proposerDetailsFromCBS?: ICustomerDetails
  customerAccountStatus: 'not-fetched' | 'loading' | 'success' | 'error'
  customerDetailsStatus: 'not-fetched' | 'loading' | 'success' | 'error'
  customerAge: number
  selectedAccountId: number
  selectedAccountPaymentMode?: TPaymentMode
  selectedAccount?: TAccount
  ckyc_number?: string
  customer_mobile_number?: string
  fieldsFetchedFromCbsMapping: {
    customerFirstName: boolean
    customerMiddleName: boolean
    customerLastName: boolean
    customerEmail: boolean
    customerMobile: boolean
    customerDob: boolean
    customerAddress1: boolean
    customerAddress2: boolean
    customerPincode: boolean
    customerPermanentPincode: boolean
  }
}

const initialState: IState = {
  accounts: [],
  customerAccountStatus: 'not-fetched',
  customerDetailsStatus: 'not-fetched',
  customerAge: 0,
  selectedAccountId: -1,
  fieldsFetchedFromCbsMapping: {
    customerFirstName: false,
    customerMiddleName: false,
    customerLastName: false,
    customerEmail: false,
    customerMobile: false,
    customerDob: false,
    customerAddress1: false,
    customerAddress2: false,
    customerPincode: false,
    customerPermanentPincode: false,
  },
}

const customerDetailsSlice = createSlice({
  name: 'customerDetailsSlice',
  initialState: initialState,
  reducers: {
    updateCustomerAccounts: (state, action: PayloadAction<TAccount[]>) => {
      const accounts = action.payload
      state.accounts = accounts
    },
    updateLifeCustomerLastName: (state, action: PayloadAction<string>) => {
      state.proposerDetailsFromCBS!.IndividualCustomerDetails.IndCustDtlsRp![0].lstNm =
        action.payload
    },
    updateCkycNumber: (state, action: PayloadAction<string>) => {
      const ckyc_number = action.payload
      state.ckyc_number = ckyc_number
    },
    updateCustomerAge: (state, action: PayloadAction<number>) => {
      const age = action.payload
      state.customerAge = age
    },
    updateCustomerMobileNumber: (state, action: PayloadAction<string>) => {
      const mobile_number = action.payload
      state.customer_mobile_number = mobile_number
    },
    updateUcicId: (state, action: PayloadAction<string>) => {
      state.ucic = action.payload
    },
    setCustomerAccountsStatus: (
      state,
      action: PayloadAction<'not-fetched' | 'loading' | 'success' | 'error'>,
    ) => {
      state.customerAccountStatus = action.payload
    },
    setSelectedAccountId: (state, action: PayloadAction<number>) => {
      state.selectedAccountId = action.payload
    },
    setSelectedAccount: (state, action: PayloadAction<TAccount>) => {
      state.selectedAccount = action.payload
    },
    setSelectedAccountPaymentMode: (state, action: PayloadAction<TPaymentMode>) => {
      state.selectedAccountPaymentMode = action.payload
    },
    setCustomerDetailsStatus: (
      state,
      action: PayloadAction<'not-fetched' | 'loading' | 'success' | 'error'>,
    ) => {
      state.customerDetailsStatus = action.payload
    },
    updateProposalDetailsFromCBS: (state, action: PayloadAction<ICustomerDetails>) => {
      state.proposerDetailsFromCBS = action.payload
    },
    updateFieldsFetchedFromCbsMapping: (state, action: PayloadAction<TCbsFields>) => {
      state.fieldsFetchedFromCbsMapping[action.payload] = true
    },
    resetCustomerDetails: () => initialState,
  },
})

const { reducer } = customerDetailsSlice

const {
  updateCustomerAccounts,
  updateCkycNumber,
  updateUcicId,
  setCustomerAccountsStatus,
  setSelectedAccountId,
  setSelectedAccount,
  resetCustomerDetails,
  updateProposalDetailsFromCBS,
  updateFieldsFetchedFromCbsMapping,
  setCustomerDetailsStatus,
  setSelectedAccountPaymentMode,
  updateCustomerMobileNumber,
  updateCustomerAge,
  updateLifeCustomerLastName,
} = customerDetailsSlice.actions

export {
  reducer as customerDetailsReducer,
  updateCustomerAccounts,
  updateCkycNumber,
  setCustomerAccountsStatus,
  setSelectedAccountId,
  setSelectedAccount,
  resetCustomerDetails,
  updateProposalDetailsFromCBS,
  updateUcicId,
  updateFieldsFetchedFromCbsMapping,
  setCustomerDetailsStatus,
  setSelectedAccountPaymentMode,
  updateCustomerMobileNumber,
  updateCustomerAge,
  updateLifeCustomerLastName,
}
