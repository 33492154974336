const API_ENDPOINTS = {
  LOGIN: '/accounts/login',
  LOGIN_SEND_OTP: '/otp/send',
  LOGIN_VERIFY_OTP: '/otp/verify',
  CHECK_ROLE: '/accounts/checkrole',
  LOGOUT: '/accounts/logout',
  // Quotations Page
  ADD_ONS: '/policy/addons',
  ADDITIONAL_COVERS: '/policy/additionalcovers',
  MOTOR_QUOTATIONS: '/policy/quotation/v2',
  PLAN_DETAILS: '/policy/details',
  AUTH: '/accounts/authtoken',
  MANUFACTURERS: '/dropdown/manufacturer',
  MODELS: '/dropdown/model',
  VARIANTS: '/dropdown/variant',
  INSURERS: '/insurer/listing',
  RTO: '/dropdown/rto',
  CPA_CHECK: '/policy/cpa',
  VAHAN_CHECK: '/policy/minimal-quote-info',
  OFFLINE_UPLOAD: '/policy/offline-upload',
  PINCODE_CMS: '/autofill/pincode',
  OCCUPATIONS: '/mapping/occupations',
  RELATIONSHIPS: '/mapping/relationships',
  MOTOR_PROPOSAL: '/policy/proposal',
  IMAGE_RC_OCR: '/policy/prefill-data-rc',
  IMAGE_POLICY_OCR: '/policy/prefill-data-policy',
  PAYMENT_CHECK: '/policy/paymentcheck/v2',
  HYPOTHECATION: '/policy/miscellaneous',
  // health
  HEALTH_QUOTATION: '/common/quotation',
  HEATH_ADDONS: '/common/addons-riders',
  HEALTH_PINCODE: '/common/pincode',
  HEALTH_SUMINSURED_GENRIC: '/common/list-suminsured',
  HEALTH_INSURER_LISTING: '/insurer/list-insurer',
  HEALTH_INSURANCE_LISTING: '/insurer/list-insurer-plans',

  HEALTH_QUES: '/common/question',
  HEALTH_LIST_MISCELLANEOUS: '/common/list-miscellaneous',
  HEALTH_BENEFITS: '/insurer/list-benifits',
  HEALTH_PROPOSAL: '/common/proposal',
  HEALTH_SALES_BANK: '/common/sales_report',
  HEALTH_COMPARE_FIELDS: '/common/list-policy-compare-fields',
  HEALTH_COMPARE_LISTING: '/common/policy-compare',
  HEALTH_CONSENT: '/common/health-constent-generate',
  HEALTH_CONSENT_SUBMIT_FORM: '/common/health-constent-submit',
  CONSENT_CHECK: '/common/health-constent-check',
  BMI_CHECK: '/common/check-bmi',
  HEALTH_PAYMENT_CHECK: '/health/payment/check',
  HEALTH_DMS_DATA_PUSH: '/common/dms-data-push',

  // equitas
  CUSTOMER_SEARCH: '/equitasacl/equitas/search',
  CUSTOMER_DETAILS: '/equitasacl/equitas/v3/cust-details',
  SP_LISTING: '/accounts/fetchspuserdatalist',
  IMAGE_RC_OCR_BANK: '/ocr/rcocr',
  IMAGE_POLICY_OCR_BANK: '/ocr/pdfocr',
  MOTOR_SALES_BANK: '/policy/policy-listing',
  MOTOR_SALES_SUMMARY_BANK: '/policy/motor/summary',
  SUPPORT_LISTING: '/accounts/listing_status',
  ISSUE_TYPE_DROPDOWN: '/accounts/issue_list',
  SUPPORT_CREATION: '/accounts/new_request_api',
  SEND_EMAIL: '/equitasacl/common/send-mail',
  SEND_SMS: '/equitasacl/common/send-sms',
  VERIFY_OTP: '/equitasacl/common/verify-mail-otp',
  MOTOR_SALES_DOWNLOAD: '/policy/report/download',
  // MOTOR_SALES_REPORT_DOWNLOAD: '/equitasacl/common/combined_report',

  GET_MOTOR_CUSTOMER_CONSENT_FORM_LINK: '/policy/motor-constent',
  SUBMIT_MOTOR_CUSTOMER_CONSENT_FORM: '/policy/motor-constent/submit',

  GET_SP_CONSENT_FORM_LINK: '/policy/sp-consent',
  SUBMIT_SP_CONSENT_FORM: '/policy/sp-consent/submit',

  CONSENT_UPLOAD: '/policy/consent-upload',

  SP_TAGGING_SEND_OTP: '/accounts/send-otp/v2',
  SP_TAGGING_VERIFY_OTP: '/accounts/validate-otp/v2',
  GET_GEO_DATA: '/dropdown/get_geo_data',

  // Life
  GET_SUITIBILITY_QUESTIONS_LIST: '/master/common/get_question_list/',
  CUSTOMER_DATA: '/product/product/life/customer',
  LIFE_QUOTES: '/product/product/life/plan-quote',
  LIFE_RECOMMENDED_PLAN: '/product/product/life/quote',
  LIFE_SINGLE_QUOTES: '/product/product/life/single-quote',
  LIFE_BI_QUOTE: '/product/product/life/plan-quote',
  GET_RIDER: '/master/rider/',
  GET_MASTER_QUESTIONS_LIST: '/master/common/get_question_list/',
  GET_LIFE_CONSENT: '/product/product/life/consent',
  GET_CHOICES: '/master/common/get-choices',
  LIFE_SALES_REPORT: '/product/product/life/policy-details',
  LIFE_DMS_DATA_PUSH: '/product/product/life/consent-upload',

  // My Leads
  SAVE_LEAD: '/leads/v1/save-lead',
  LEAD_LISTING: '/leads/v2/my-leads',
  LEAD_REASSIGN: '/leads/v2/reassign',
  CLOSE_LEAD: '/leads/v1/my-leads/close',
  DOWNLOAD_REPORT: '/leads/v1/my-leads/report/download',
}

export { API_ENDPOINTS }
