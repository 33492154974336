import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Image,
  PinInput,
  PinInputField,
  Text,
  border,
} from '@chakra-ui/react'
import { useAppSelector, useDeviceType, useAppDispatch } from 'app/hooks'
import { CommonImages } from 'Assets/Common'
import hospitalCashOver from 'Assets/Common/Home/hospitalCashOver.png'
import personalAccidentCover from 'Assets/Common/Home/personalAccidentCover.png'
import criticalIllnessCover from 'Assets/Common/Home/criticalllnessCover.png'
import bnwHospitalCover from 'Assets/Common/Home/bnwHospitalCover.png'
import bnwPersonalAccidentCover from 'Assets/Common/Home/bnwPersonalAccidentCover.png'
import bnwCriticalIllnessCover from 'Assets/Common/Home/bnwCriticalIllnessCover.png'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const downloadCSVFile = async (link: string, fileName: string) => {
  const data = await fetch(link)
  const blob = await data.blob()
  const objectUrl = URL.createObjectURL(blob)
  const aTag: HTMLAnchorElement = document.createElement('a')
  aTag.href = objectUrl
  aTag.target = '_blank'
  aTag.download = fileName + '.pdf'
  aTag.click()
}

const HomeSachetForm = () => {
  // const {
  //   HomeImages: { sachetInsurance },
  // } = CommonImages

  const [activeHover, setActiveHover] = useState<number>(0)
  const navigate = useNavigate()

  const dataObj = [
    {
      id: 1,
      image: hospitalCashOver,
      blackAndWhiteImage: bnwHospitalCover,
      subHeading: 'Hospital Cash Cover',
      subText:
        'Offers a daily cash benefit to cover out-of-pocket expenses while hospitalized for an illness or injury.',

      buttonPath:
        'https://hegilead.hdfcergo.com/EqSspgApp/HEGIApp/HEGICLead?Source=TYll6xiaipA=&SubChannel=WqscUcmCNPI=&EntityName=WqscUcmCNPI=',
      knowMoreButton: 'https://foundation.theblackswan.in/media/Kz6jds6JkLfsGPA-nL5E8Q==',
      fileName: 'Hospital Cash Cover',
    },
    {
      id: 2,
      image: personalAccidentCover,
      blackAndWhiteImage: bnwPersonalAccidentCover,
      subHeading: 'Personal Accident Cover',
      subText:
        'Provides financial protection in the event of an accident resulting in injury, disability, or death. ',
      buttonPath:
        'https://hegilead.hdfcergo.com/EqSspgApp/HEGIApp/HEGICLead?Source=TYll6xiaipA=&SubChannel=WqscUcmCNPI=&EntityName=WqscUcmCNPI=',
      knowMoreButton: 'https://foundation.theblackswan.in/media/ZCgpjUoZgtu806ugEazslA==',
      fileName: 'Personal Accident Cover',
    },
    {
      id: 3,
      image: criticalIllnessCover,
      blackAndWhiteImage: bnwCriticalIllnessCover,
      subHeading: 'Critical Illness Cover',
      subText:
        'Pays a lump sum amount upon diagnosis of specified critical illnesses, providing financial support during difficult times.',
      buttonPath: 'https://sda.in.net/web/rgi/HospicashEquitas/',
      knowMoreButton: 'https://foundation.theblackswan.in/media/Wms0_aILJdg5nNBahVZkAw==',
      fileName: 'Critical Illness Cover',
    },
    {
      id: 4,
      image: criticalIllnessCover,
      blackAndWhiteImage: bnwCriticalIllnessCover,
      subHeading: 'Cancer Care Cover',
      subText:
        'A cancer cover plan provides financial support for diagnosis and treatment, covering expenses like chemotherapy and surgery',
      buttonPath:
        'https://instasaral.nivabupa.com/Cypher/PrePop/PrePop?token=MkBnHC9K1L2s%2bwgPgyJrq%2fdJjteNSXF%2fJ%2fy86Bsg6B4%3d',
      knowMoreButton: 'https://foundation.theblackswan.in/media/Wms0_aILJdg5nNBahVZkAw==',
      fileName: 'Cancer Care Cover',
    },
  ]

  return (
    <>
      <Center gap='14' alignContent={'center'} alignItems={'center'}>
        {dataObj.map((item, index) => (
          <Box
            mx='10px'
            width={'220px'}
            borderRadius={'20px'}
            my='50px'
            shadow={'lg'}
            key={index}
            border={item.id === activeHover ? '1px solid #1C4589' : ''}
            // _hover={{ border: '1px solid #1C4589', color: '#00548C' }}
            onMouseEnter={() => setActiveHover(item.id)}
            onMouseLeave={() => setActiveHover(0)}
          >
            <Center flexDirection={'column'} mt='20px'>
              {item.id === activeHover ? (
                <img src={item.image} alt='an image' height={'100px'} width={'100px'} />
              ) : (
                <img src={item.blackAndWhiteImage} alt='image' height={'100px'} width={'100px'} />
              )}
              <Text my='5' color={'#414141'}>
                {item.subHeading}
              </Text>

              <Text color={'#949494'} fontSize={'11px'} mx='20px'>
                {item.subText}
                <br />
                <span
                  style={{
                    color: '#1C4589',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    fontSize: '10px',
                  }}
                >
                  <a onClick={() => downloadCSVFile(item.knowMoreButton, item.fileName)}>
                    Know More
                  </a>
                </span>
              </Text>
              <a href={item.buttonPath} target='_blank' rel='noreferrer'>
                <Button my='5'>Proceed</Button>
              </a>
            </Center>
          </Box>
        ))}
      </Center>
    </>
  )
}

export { HomeSachetForm }
