import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { MemberData } from 'Models/Common/Health.General'
import { ICustomerDetails, TAccount } from 'Models/ResponseModels/Equitas'
import { IHealthPincode } from 'Models/ResponseModels/Health'
import { ListingResponse, RecommendedPlan } from 'Models/ResponseModels/Life'
import { stat } from 'fs'

type TPaymentMode = 'PG' | 'DirectDebit'
interface IState {
  accounts?: TAccount[]
  ucic?: string
  rider?: Record<string, string | number | boolean | string[]>
  is_same?: boolean
  relationship?: string
  acceptedSalutation?: string[]
  selected_plan?: RecommendedPlan
  selectedRecommendedPlan: string
  smokerStatus?: string
  lifeProposerDetails?: ICustomerDetails
  customerAccountStatus: 'not-fetched' | 'loading' | 'success' | 'error'
  customerDetailsStatus: 'not-fetched' | 'loading' | 'success' | 'error'
  selectedAccountId: number
  selectedAccountPaymentMode?: TPaymentMode
  selectedAccount?: TAccount
  selectedLifeAssuredAccount?: TAccount
  ckyc_number?: string
  fieldsFetchedFromCbsMapping: {
    customerFirstName: boolean
    customerMiddleName: boolean
    customerLastName: boolean
    customerEmail: boolean
    customerMobile: boolean
    customerDob: boolean
    customerAddress1: boolean
    customerAddress2: boolean
    customerPincode: boolean
    customerPermanentPincode: boolean
    customerPan: boolean
    customerLandmark: boolean
  }
  proposer: {
    type: string
    member_type: string
    salutation: { name: string; code: string }
    age: string
    dob: string
    pincode: string
    proposer: 1
    gender: string
    city_name: string
    state_name: string
    nationality: string
    selected: false
    code: string
    marital_status: { name: string; code: string }
    member_insured: string
    firstname: string
    middlename: string
    lastname: string
    relationship_with_proposer: string
    mobile: string
    email: string
    occupation: { name: string; code: string }
    bmi: string
    height: string
    weight: string
    educational_qualification: { name: string; code: string }
    annual_income: string
    aadhar_card_number: string
    pan_number: string
    current_address: string
    pincode_map: {
      pincode: string
      area_name: string
      area_code: string
      city_code: string
      city_name: string
      state_name: string
      state_code: string
      is_district: null
      area_id: null
      extra_info: any
      id: null
      is_insurance_wise: null
    }
    permanent_address: string
    permanent_address_pincode_map: {
      pincode: string
      area_name: string
      area_code: string
      city_code: string
      city_name: string
      state_name: string
      state_code: string
      is_district: null
      area_id: null
      extra_info: any
      id: null
      is_insurance_wise: null
    }
    nominee_details: {
      salutation: { name: string; code: string }
      firstname: string
      middlename: string
      lastname: string
      dob: string
      gender: string
      relationship: { name: string; code: string }
      mobile: string
      occupation: { name: string; code: string }
      address: string
      marital_status: { name: string; code: string }
      pincode_map: {
        pincode: string
        area_name: string
        area_code: string
        city_code: string
        city_name: string
        state_name: string
        state_code: string
        is_district: null
        area_id: null
        extra_info: any
        id: null
        is_insurance_wise: null
      }
    }
    medical_questions: []
    lifestyle_questions: []
    selectedQuoteNumber: string
  }
  lifeAssuredDetails?: ICustomerDetails
  lifeAssuredSmokerStatus: string
  lifeAssuredDetailsTyped?: {
    address1: string
    address2: string
    dob: string
    email: string
    firstName: string
    gender: ListingResponse
    lastName: string
    marital: ListingResponse
    middleName: string
    mobile: string
    pan: string
    pincode: string
    title: ListingResponse
    landmark?: string
  }
}

const initialState: IState = {
  accounts: [],
  lifeAssuredSmokerStatus: 'Smoker',
  ucic: '',
  is_same: false,
  relationship: '',
  acceptedSalutation: [],
  smokerStatus: 'Non-Smoker',
  selectedRecommendedPlan: 'Term',
  customerAccountStatus: 'not-fetched',
  customerDetailsStatus: 'not-fetched',
  selectedAccountId: -1,
  fieldsFetchedFromCbsMapping: {
    customerFirstName: false,
    customerMiddleName: false,
    customerLastName: false,
    customerEmail: false,
    customerMobile: false,
    customerDob: false,
    customerAddress1: false,
    customerAddress2: false,
    customerPincode: false,
    customerPermanentPincode: false,
    customerPan: false,
    customerLandmark: false,
  },
  proposer: {
    type: 'adult',
    member_type: 'proposer',
    salutation: { name: '', code: '' },
    age: '',
    dob: '',
    pincode: '',
    proposer: 1,
    gender: '',
    city_name: '',
    state_name: '',
    nationality: '',
    selected: false,
    code: '',
    marital_status: { name: '', code: '' },
    member_insured: '',
    firstname: '',
    middlename: '',
    lastname: '',
    relationship_with_proposer: '',
    mobile: '',
    email: '',
    occupation: { name: '', code: '' },
    bmi: '',
    height: '',
    weight: '',
    educational_qualification: { name: '', code: '' },
    annual_income: '',
    aadhar_card_number: '',
    pan_number: '',
    current_address: '',
    pincode_map: {
      pincode: '',
      area_name: '',
      area_code: '',
      city_code: '',
      city_name: '',
      state_name: '',
      state_code: '',
      is_district: null,
      area_id: null,
      extra_info: {},
      id: null,
      is_insurance_wise: null,
    },
    permanent_address: '',
    permanent_address_pincode_map: {
      pincode: '',
      area_name: '',
      area_code: '',
      city_code: '',
      city_name: '',
      state_name: '',
      state_code: '',
      is_district: null,
      area_id: null,
      extra_info: {},
      id: null,
      is_insurance_wise: null,
    },
    nominee_details: {
      salutation: { name: '', code: '' },
      firstname: '',
      middlename: '',
      lastname: '',
      dob: '',
      gender: '',
      relationship: { name: '', code: '' },
      mobile: '',
      occupation: { name: '', code: '' },
      address: '',
      marital_status: { name: '', code: '' },
      pincode_map: {
        pincode: '',
        area_name: '',
        area_code: '',
        city_code: '',
        city_name: '',
        state_name: '',
        state_code: '',
        is_district: null,
        area_id: null,
        extra_info: {},
        id: null,
        is_insurance_wise: null,
      },
    },
    medical_questions: [],
    lifestyle_questions: [],
    selectedQuoteNumber: '',
  },
}

const lifeCustomerDetailsSlice = createSlice({
  name: 'lifeCustomerDetails',
  initialState: initialState,
  reducers: {
    //    updateCustomer: (
    //   state,
    //   action: PayloadAction<{
    //     data: MemberData
    //     isProposerIncluded: boolean
    //   }>,
    // ) => {
    //     state.proposer = {
    //       ...state.proposer,
    //       ...action.payload.data,
    //     }
    // }
    updateLifeProposalDetails: (state, action: PayloadAction<ICustomerDetails>) => {
      state.lifeProposerDetails = action.payload
    },
    updateLifeAssuredDetails: (state, action: PayloadAction<ICustomerDetails>) => {
      state.lifeAssuredDetails = action.payload
    },
    updateLifeAssuredDetailsTyped: (state, action: PayloadAction<any>) => {
      state.lifeAssuredDetailsTyped = action.payload
    },
    updateLifeAssuredSmokerStatus: (state, action: PayloadAction<string>) => {
      state.lifeAssuredSmokerStatus = action.payload
    },
    updateUCICId: (state, action: PayloadAction<any>) => {
      state.ucic = action.payload.ucic
      state.is_same = action.payload.is_same
      state.relationship = action.payload.relationship
    },
    updateAcceptedSalutation: (state, action: PayloadAction<string[]>) => {
      state.acceptedSalutation = action.payload
    },

    updateSmokerStatus: (state, action: PayloadAction<any>) => {
      state.smokerStatus = action.payload.smokerStatus
      // console.log(action.payload.smokerStatus, 'action.payload.smokerStatus')
    },
    updateSelectedPlan: (state, action: PayloadAction<RecommendedPlan>) => {
      state.selected_plan = action.payload
    },
    updateSelectedRecommendedPlan: (state, action: PayloadAction<any>) => {
      state.selectedRecommendedPlan = action.payload.selectedRecommendedPlan
    },
    updateRider: (
      state,
      action: PayloadAction<Record<string, string | number | boolean | string[]> | undefined>,
    ) => {
      state.rider = action.payload
    },

    updateLifeCustomerData: (state, action: PayloadAction<any>) => {
      state.lifeProposerDetails!.IndividualCustomerDetails.IndCustDtlsRp![0] = {
        email: action.payload.email,
        mblNb: action.payload.mobile,
        frsNm: action.payload.firstName,
        lstNm: action.payload.lastName,
        mdlNm: action.payload.middleName,
        brnchId: state.lifeProposerDetails!.IndividualCustomerDetails.IndCustDtlsRp![0]?.brnchId,
        brnchNm: state.lifeProposerDetails!.IndividualCustomerDetails.IndCustDtlsRp![0]?.brnchNm,
      }

      state.lifeProposerDetails!.CustmrFlds.CustmrFldsRp = {
        dob: action.payload.dob,
        title: action.payload.title,
        gndr: action.payload.gender || {},
        panNb: action.payload.pan,
        crntAddrLn1: action.payload.address1,
        crntAddrLn2: action.payload.address2,
        crntPncd: action.payload.pincode,
        mrtlSts: action.payload.marital || '',
      }
    },
    updateLifeAssuredAccount: (state, action: PayloadAction<TAccount>) => {
      state.selectedLifeAssuredAccount = action.payload
    },
    resetLifeCustomerDetails: () => initialState,
  },
})

const { reducer } = lifeCustomerDetailsSlice
const {
  updateLifeProposalDetails,
  resetLifeCustomerDetails,
  updateUCICId,
  updateLifeCustomerData,
  updateLifeAssuredDetails,
  updateLifeAssuredDetailsTyped,
  updateSmokerStatus,
  updateSelectedPlan,
  updateRider,
  updateSelectedRecommendedPlan,
  updateLifeAssuredAccount,
  updateLifeAssuredSmokerStatus,
  updateAcceptedSalutation,
} = lifeCustomerDetailsSlice.actions

export {
  reducer as lifeCustomerDetailsReducer,
  updateLifeProposalDetails,
  resetLifeCustomerDetails,
  updateUCICId,
  updateAcceptedSalutation,
  updateLifeCustomerData,
  updateLifeAssuredDetailsTyped,
  updateLifeAssuredDetails,
  updateSmokerStatus,
  updateSelectedPlan,
  updateLifeAssuredSmokerStatus,
  updateLifeAssuredAccount,
  updateRider,
  updateSelectedRecommendedPlan,
}
