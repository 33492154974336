import { useAppSelector, useVehicleType } from 'app/hooks'
import { TVehicleTypeParam } from 'app/hooks/useVehicleType'
import { store } from 'app/store'
import { hypothecationCheckInsurers } from 'Constants'
import { differenceInYears } from 'date-fns'
import { TMotorProposalPayload } from 'Models/RequestModels'
import { ICustomerDataForConsent } from 'Models/RequestModels/Equitas'
import { UseFormReturn } from 'react-hook-form'
import { getAccountTypeBasedOnIndex, getDateFromDDMMYYYY } from 'Services/general'
import { TMotorProposalFormType } from './Forms/FormModel'

interface IGetProposalParams {
  proposalDetails: TMotorProposalFormType
  vehicleType: '' | 'is_fourwheeler' | 'is_twowheeler' | 'is_gcv' | 'is_pcv' | 'is_misc'
}

const getProposalPayload = (data: IGetProposalParams) => {
  const { proposalDetails, vehicleType } = data
  const {
    homePage: { vehicleNumber },
    vehicleDetails: { vehicleDetails, vehicleInsuranceDetails },
    quotationsDetails,
    customerDetails,
    spTagging: { selectedSp },
  } = store.getState()

  const payload: TMotorProposalPayload = {
    common: {
      insurance_code: quotationsDetails.selectedQuote?.insurer_code,
      insurance_type: 'NEW',
      insurer_name: quotationsDetails.selectedQuote?.insurance_name,
    },
    ckyc_number: customerDetails.ckyc_number ?? '',
    pan: customerDetails.proposerDetailsFromCBS?.CustmrFlds.CustmrFldsRp.panNb ?? '',
    customer: {
      address: proposalDetails.address1,
      address1: proposalDetails.address2,
      area_code: proposalDetails.pincodeArea?.area,
      city: proposalDetails.pincodeArea?.city,
      landmark: proposalDetails.landmark,
      email: proposalDetails.email,
      phone: proposalDetails.mobileNumber,
      pincode: proposalDetails.pincodeArea?.pincode,
      state: proposalDetails.pincodeArea?.state,
      type: quotationsDetails.isIndividual ? 'Individual' : 'Company',
    },
    idv: {},
    prev_policy: {
      claims: vehicleInsuranceDetails.claimsMadeInExistingPolicy === true ? 'Y' : 'N',
    },
    unique_identifier: quotationsDetails.selectedQuote?.unique_identifier,
    vehicle: {
      chassis_number: proposalDetails.chassisNumber,
      engine_number: proposalDetails.engineNumber,
      is_vehicle_new: 0,
      manufacturer_make: vehicleDetails.selectedManufacturer?.code,
      manufacturer_model: vehicleDetails.selectedModel?.code,
      manufacturer_variant: vehicleDetails.selectedVariant?.code,
      manufacturing_date: vehicleDetails.manufacturingYear,
      purchase_date: vehicleDetails.registrationDate,
      registration_date: vehicleDetails.registrationDate,
      registration_no: vehicleNumber,
      agreement_type: proposalDetails.agreementType,
      rto: vehicleNumber.replaceAll('-', '').slice(0, 4).toLowerCase(),
      class: 'Private',
    },
  }

  if (
    quotationsDetails.selectedQuote?.premium_total &&
    quotationsDetails.selectedQuote?.premium_total > 50000
  ) {
    payload.customer.pan_number = proposalDetails.panCard
  }

  // TODO:
  // if(quotationsDetails.paOwnerReason) {
  //   payload.pa_to_owner = quotationsDetails.paOwnerReason
  // }

  if (quotationsDetails.selectedQuote?.insurance_name.includes('HDFC')) {
    payload.customer.mother_name = proposalDetails.mothersName
    payload.customer.annual_income = proposalDetails.annualIncome
    payload.customer.organization_type = proposalDetails.organizationType
    payload.customer.resident_indian_flag = proposalDetails.isIndian ? '1' : '0'
    payload.customer.is_politically_exposed = proposalDetails.isPoliticallyExposed ? '1' : '0'
  }

  console.log('proposalDetails.isLoan', proposalDetails.isLoan)
  if (proposalDetails.isLoan === true) {
    payload.vehicle.loan = proposalDetails.isLoan === true ? '1' : '0'
    payload.vehicle.bank_name = proposalDetails.bankName
    payload.vehicle.branch_name = proposalDetails.branchName
    if (hypothecationCheckInsurers.includes(quotationsDetails.selectedQuote?.insurer_code ?? '')) {
      payload.vehicle.hypothecation_id = proposalDetails.hypothecation?.id
      payload.vehicle.bank_name = proposalDetails.hypothecation?.bank_name
    }
  } else {
    payload.vehicle.loan = '0'
  }
  // else if (quotationsDetails.isComprehensive) {
  //   payload.vehicle.loan = '0'
  // }

  // TODO:
  // if (proposalPageController.bank.value != null) {
  //   payload['vehicle']['hypothecation_id'] = proposalPageController.bank.value!.id.toString();
  // }

  // TODO:
  // if (vehicleDetailsPageController.typeOfVehicle.value != "" && vehicleDetailsPageController.seatsGreaterThanSix.value) {
  //   payload['vehicle']['vehicle_type'] = vehicleDetailsPageController.typeOfVehicle.value;
  // }

  if (vehicleType === 'is_gcv' || vehicleType === 'is_pcv') {
    payload['vehicle']['usage_type'] = proposalDetails.usesType?.code
  }

  if (quotationsDetails.isIndividual) {
    payload.customer.firstname = proposalDetails.firstName
    payload.customer.middlename = proposalDetails.middleName
    payload.customer.lastname = proposalDetails.lastName
    payload.customer.dob = proposalDetails.dob
    payload.customer.gender = proposalDetails.isGenderMale ? 'male' : 'female'
    payload.customer.marital_status = proposalDetails.isMarried ? 'M' : 'S'
    payload.customer.occupation = proposalDetails.occupation
    payload.customer.salutation = proposalDetails.salutation

    payload.idv.nominee = proposalDetails.nomineeFirstName
    payload.idv.nominee_middle_name = proposalDetails.nomineeMiddleName
    payload.idv.nominee_last_name = proposalDetails.nomineeLastName
    payload.idv.nominee_dob = proposalDetails.nomineeDob
    if (payload.idv.nominee_dob) {
      payload.idv.nominee_age = differenceInYears(
        new Date(),
        getDateFromDDMMYYYY(payload.idv.nominee_dob),
      ).toString()
    }
    payload.idv.relationship = proposalDetails.nomineeRelationship
  }

  console.log('quotationsDetails.isIndividual - ', quotationsDetails.isIndividual)

  if (!quotationsDetails.isIndividual) {
    payload.customer.gst_no = proposalDetails.gstNumber
    payload.customer.company_name = proposalDetails.companyName
  }

  payload.prev_policy.insurer_name = vehicleInsuranceDetails.previousInsurer?.name
  payload.prev_policy.policy_no = proposalDetails.previousPolicyNumber
  payload.prev_policy.end_date = vehicleInsuranceDetails.existingPolicyExpiryDate
  payload.prev_policy.previous_policy_type = vehicleInsuranceDetails.previousPolicyType?.value

  payload.sp_id = selectedSp?.id?.toString()

  // TODO:
  // if (quotationPageController.shouldPassZeroDepKey.value) {
  //   payload['zero_dept_taken_last_year'] = quotationPageController.zeroDeptTakenLastYear.value;
  // }

  // payload.unique_identifier = quotationsDetails.selectedQuote?.unique_identifier ?? ''

  // payload.common.insurance_code = quotationsDetails.selectedQuote?.insurer_code
  // payload.common.insurer_name = quotationsDetails.selectedQuote?.insurance_name

  // payload.vehicle.loan = proposalDetails.isLoan ? 1 : 0
  // payload.vehicle.is_vehicle_new = 0
  // payload.vehicle.rto = vehicleNumber.replaceAll('-', '').slice(0, 4).toLowerCase()
  // payload.vehicle.registration_date = vehicleDetails.registrationDate
  // payload.vehicle.registration_no = vehicleNumber
  // payload.vehicle.manufacturing_date = vehicleDetails.manufacturingYear
  // payload.vehicle.manufacturer_make = vehicleDetails.selectedManufacturer?.code
  // payload.vehicle.manufacturer_model = vehicleDetails.selectedModel?.code
  // payload.vehicle.manufacturer_variant = vehicleDetails.selectedVariant?.code
  // payload.vehicle.chassis_number = proposalDetails.chassisNumber
  // payload.vehicle.engine_number = proposalDetails.engineNumber
  // payload.vehicle.purchase_date = vehicleDetails.registrationDate

  // payload.customer.salutation = proposalDetails.salutation
  // payload.customer.email = proposalDetails.email
  // payload.customer.gender = proposalDetails.isGenderMale ? 'male' : 'female'
  // payload.customer.marital_status = proposalDetails.isMarried ? 'M' : 'S'
  // payload.customer.phone = proposalDetails.mobileNumber
  // payload.customer.landmark = proposalDetails.landmark
  // payload.customer.address = proposalDetails.address1
  // payload.customer.address1 = proposalDetails.address2
  // payload.customer.state = proposalDetails.pincodeArea?.state
  // payload.customer.city = proposalDetails.pincodeArea?.city
  // payload.customer.pincode = proposalDetails.pincodeArea?.pincode
  // payload.customer.area_code = proposalDetails.pincodeArea?.area
  // payload.customer.dob = proposalDetails.dob
  // payload.customer.type = quotationsDetails.isIndividual ? 'Individual' : 'Company'
  // payload.customer.firstname = proposalDetails.firstName
  // payload.customer.middlename = proposalDetails.middleName
  // payload.customer.lastname = proposalDetails.lastName

  // payload.customer.gst_no = proposalDetails.gstNumber

  // payload.customer.company_name = proposalDetails.companyName

  // payload.customer.occupation = proposalDetails.occupation

  // payload.prev_policy.claims = 'N'

  return payload
}

const setProposalValueInForm = (
  proposalDetailValues: TMotorProposalFormType,
  form: UseFormReturn<TMotorProposalFormType, any>,
) => {
  const {
    engineNumber,
    chassisNumber,
    previousPolicyNumber,
    isLoan,
    bankName,
    hypothecation,
    salutation,
    firstName,
    middleName,
    lastName,
    isGenderMale,
    isMarried,
    companyName,
    panCard,
    gstNumber,
    mobileNumber,
    email,
    dob,
    pincodeArea,
    occupation,
    address1,
    address2,
    landmark,
    nomineeFirstName,
    nomineeMiddleName,
    nomineeLastName,
    nomineeDob,
    nomineeAge,
    nomineeRelationship,
    usesType,
    spData,
  } = proposalDetailValues

  console.log('companyName', companyName)

  engineNumber && form.setValue('engineNumber', engineNumber)
  chassisNumber && form.setValue('chassisNumber', chassisNumber)
  previousPolicyNumber && form.setValue('previousPolicyNumber', previousPolicyNumber)
  isLoan !== undefined && form.setValue('isLoan', isLoan)
  bankName && form.setValue('bankName', bankName)
  hypothecation && form.setValue('hypothecation', hypothecation)
  salutation && form.setValue('salutation', salutation)
  firstName && form.setValue('firstName', firstName)
  middleName && form.setValue('middleName', middleName)
  lastName && form.setValue('lastName', lastName)
  isGenderMale && form.setValue('isGenderMale', isGenderMale)
  isMarried && form.setValue('isMarried', isMarried)
  companyName && form.setValue('companyName', companyName)
  panCard && form.setValue('panCard', panCard)
  gstNumber && form.setValue('gstNumber', gstNumber)
  mobileNumber && form.setValue('mobileNumber', mobileNumber)
  email && form.setValue('email', email)
  dob && form.setValue('dob', dob)
  pincodeArea && form.setValue('pincodeArea', pincodeArea)
  occupation && form.setValue('occupation', occupation)
  address1 && form.setValue('address1', address1)
  address2 && form.setValue('address2', address2)
  landmark && form.setValue('landmark', landmark)
  nomineeFirstName && form.setValue('nomineeFirstName', nomineeFirstName)
  nomineeMiddleName && form.setValue('nomineeMiddleName', nomineeMiddleName)
  nomineeLastName && form.setValue('nomineeLastName', nomineeLastName)
  nomineeDob && form.setValue('nomineeDob', nomineeDob)
  nomineeAge && form.setValue('nomineeAge', nomineeAge)
  nomineeRelationship && form.setValue('nomineeRelationship', nomineeRelationship)
  usesType && form.setValue('usesType', usesType)
  spData && form.setValue('spData', spData)
}

const getConsentFormPayload = (vehicleType: TVehicleTypeParam, vehicleName: string) => {
  const {
    homePage: { vehicleNumber },
    vehicleDetails: { vehicleDetails, vehicleInsuranceDetails },
    quotationsDetails,
    proposalPage: { proposalDetails },
    spTagging: { selectedSp },
    customerDetails,
  } = store.getState()

  const payload: ICustomerDataForConsent = {
    ucic_id: customerDetails.ucic,
    ifsc: customerDetails.selectedAccount?.ifsc_code
      ? customerDetails.selectedAccount?.ifsc_code.toString()
      : '',
    account_type: getAccountTypeBasedOnIndex(customerDetails.selectedAccount?.account_type ?? ''),
    vehicle_type: vehicleName,
    reg_no: vehicleNumber,
    make: vehicleDetails.selectedManufacturer?.name,
    model: vehicleDetails.selectedModel?.name,
    variant: vehicleDetails.selectedVariant?.name,
    seating_capacity: vehicleDetails.selectedVariant?.seating_capacity?.toString() ?? '',
    manufacturing_year: vehicleDetails.manufacturingYear?.toString() ?? '',
    registration_date: vehicleDetails.registrationDate,
    puc: 'Yes',
    engine_no: proposalDetails.engineNumber,
    chassis_no: proposalDetails.chassisNumber,
    is_loan: proposalDetails.isLoan === true ? 'Yes' : 'No',
    mobile_no: proposalDetails.mobileNumber,
    email_id: proposalDetails.email ?? '',
    pincode: proposalDetails.pincodeArea?.pincode
      ? proposalDetails.pincodeArea?.pincode.toString()
      : '',
    city: proposalDetails.pincodeArea?.city_name,
    state: proposalDetails.pincodeArea?.state_name,
    address1: proposalDetails.address1,
    address2: proposalDetails.address2,
    landmark: proposalDetails.landmark,
  }

  // vehicle insurance details
  if (vehicleInsuranceDetails.hasPreviousPolicy === true) {
    payload['has_previous_policy'] = 'Yes'
    payload['existingPolicyExpiryDate'] = vehicleInsuranceDetails.existingPolicyExpiryDate
    payload['prev_insurer'] = vehicleInsuranceDetails.previousInsurer?.name
    payload['prev_policy_type'] = vehicleInsuranceDetails.previousPolicyType?.name
    console.log(
      'vehicleInsuranceDetails.claimsMadeInExistingPolicy - ',
      vehicleInsuranceDetails.claimsMadeInExistingPolicy,
    )
    if (vehicleInsuranceDetails.claimsMadeInExistingPolicy === false) {
      payload['claims_made_in_existing_policy'] = 'No'
      payload['ncb'] = vehicleInsuranceDetails.ncb
    } else {
      payload['claims_made_in_existing_policy'] = 'Yes'
    }
    if (vehicleInsuranceDetails.isSaod === true) {
      payload['prev_policy_type'] = 'Stand Alone OD'
      payload['tp_prev_policy_number'] = vehicleInsuranceDetails.TpPreviousPolicyNumber
      payload['tp_prev_insurer_name'] = vehicleInsuranceDetails.TpPreviousInsurerName?.name
      payload['tp_policy_start_date'] = vehicleInsuranceDetails.tpPolicyStartDate
      payload['tp_policy_end_date'] = vehicleInsuranceDetails.tpPolicyEndDate
    }
  } else {
    payload['has_previous_policy'] = 'No'
  }

  if (vehicleType === 'is_gcv') {
    payload['permit_type'] = vehicleInsuranceDetails.permitType?.name
  }

  // quotations
  payload['package_type'] = vehicleInsuranceDetails.isSaod
    ? 'Stand Alone OD'
    : quotationsDetails.isComprehensive
    ? 'Comprehensive'
    : 'Third Party'

  payload['owned_by'] = quotationsDetails.isIndividual ? 'Individual' : 'Company'

  // proposal
  if (proposalDetails.isLoan === true) {
    if (proposalDetails.hypothecation?.bank_name) {
      payload['bank_name'] = proposalDetails.hypothecation?.bank_name
    } else {
      payload['bank_name'] = proposalDetails.bankName
    }
  }
  if (vehicleType === 'is_gcv' || vehicleType === 'is_pcv') {
    payload['uses_type'] = proposalDetails.usesType?.name
  }
  if (vehicleInsuranceDetails.hasPreviousPolicy === true) {
    payload['prev_policy_no'] = proposalDetails.previousPolicyNumber
  }
  if (quotationsDetails.isIndividual) {
    payload['first_name'] = proposalDetails.firstName
    payload['middle_name'] = proposalDetails.middleName
    payload['last_name'] = proposalDetails.lastName
    payload['gender'] = proposalDetails.isGenderMale ? 'Male' : 'Female'
    payload['marital_status'] = proposalDetails.isMarried ? 'Married' : 'Single'
    payload['dob'] = proposalDetails.dob
    payload['occupation'] = proposalDetails.occupation
  } else {
    payload['company_name'] = proposalDetails.companyName
    payload['gst_no'] = proposalDetails.gstNumber
  }
  if (
    quotationsDetails.selectedQuote?.premium_total &&
    quotationsDetails.selectedQuote?.premium_total > 50000
  ) {
    payload['pancard'] = proposalDetails.panCard
  }

  if (quotationsDetails.isIndividual) {
    payload['nominee_first_name'] = proposalDetails.nomineeFirstName
    payload['nominee_middle_name'] = proposalDetails.nomineeMiddleName
    payload['nominee_last_name'] = proposalDetails.nomineeLastName
    payload['nominee_relationship'] = proposalDetails.nomineeRelationship
    payload['nominee_dob'] = proposalDetails.nomineeDob
    payload['nominee_age'] = proposalDetails.nomineeAge
  }

  return payload
}

const getInsuranceTypeText = ({
  vehicleTypeParam,
  isCurrentTP,
  isPreviousTP,
  isBreakIn,
}: {
  vehicleTypeParam: string
  isCurrentTP: boolean
  isPreviousTP: boolean
  isBreakIn: boolean
}): string => {
  try {
    if (
      vehicleTypeParam === 'is_fourwheeler' ||
      vehicleTypeParam === 'is_gcv' ||
      vehicleTypeParam === 'is_pcv'
    ) {
      // packagetype - tp
      if (isCurrentTP) {
        return 'Third party policy - Inspection not Required'
      }
      // breakin
      else if (isBreakIn) {
        return 'Comprehensive - Inspection Required'
      }
      // roll over
      else {
        if (isPreviousTP && !isCurrentTP) {
          return 'Comprehensive - Inspection Required'
        } else {
          return 'Comprehensive - Inspection not Required'
        }
      }
    } else if (vehicleTypeParam === 'is_twowheeler') {
      if (isBreakIn) {
        return 'Breakin - Inspection not Required'
      } else {
        return 'Rollover - Inspection not Required'
      }
    } else {
      return ''
    }
  } catch (e) {
    return ''
  }
}

export { getProposalPayload, setProposalValueInForm, getInsuranceTypeText, getConsentFormPayload }
