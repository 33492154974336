import {
  Box,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { ElevatedButton, HeadingText, PaymentSuccessDialog } from 'Core'
import { MdBuild } from 'react-icons/md'
import { useAppDispatch, useAppSelector, useDeviceType, useVehicleType } from 'app/hooks'
import { VehicleInsuranceDetailsMobile } from './Mobile'
import { VehicleInsuranceDetailsDesktop } from './Desktop'
import { Header } from 'Components'
import { useEffect, useState } from 'react'
import {
  useGetInsurersQuery,
  useLazyGetInsurersQuery,
  useLazyGetManufacturersQuery,
  useLazyGetModelsQuery,
  useLazyGetVariantsQuery,
  useVahanPrefillMutation,
  useCheckForCpaMutation,
} from 'Services/API/motor.api'
import {
  updateFieldsFetchedFromVahanMapping,
  updateManufacturer,
  updateManufacturingYear,
  updateModel,
  updateProposalDetails,
  updateRegistrationDate,
  updateShowConsent,
  updateVahanDetails,
  updateVariant,
  updateVehicleDetailsForm,
  updateVehicleDetailsFormLeads,
  updateVehicleInsuranceForm,
} from 'features/Motor'
import { useSaveLeadsMutation, useCloseLeadMutation } from 'Services/API/leads.api'
import { addDays, differenceInDays, format, isValid } from 'date-fns'
import { ToastService } from 'Services/toast.service'
import { useForm } from 'react-hook-form'
import { TVehicleInsuranceFormType } from './Forms/FormModel/VehicleInsurance'
import parse from 'date-fns/parse'
import { mobileNumRegex } from 'Constants'
import { ISaveLead } from 'Models/RequestModels/Leads'
import { updateDropOff, updateLeadID } from 'features/Leads'
import { updateFieldsFetchedFromCbsMapping } from 'features/Equitas'
import { copyToClipboard, decryptData, union } from 'Services/general'
import { useNavigate } from 'react-router-dom'
import { JourneyBlockDialog } from '../Equitas/JourneyBlockDialog'
import { boolean } from 'zod'
import { ISaveLeadResponse } from 'Models/ResponseModels'

const VehicleInsuranceDetails = () => {
  const {
    isOpen: isBlockJourneyOpen,
    onOpen: onBlockJourneyOpen,
    onClose: onBlockJourneyClose,
  } = useDisclosure()

  const [cbsName, setCbsName] = useState({
    fetched: '0',
    firstName: '',
    middleName: '',
    lastName: '',
  })
  const [vahanName, setVahanName] = useState({
    fetched: '0',
    firstName: '',
    middleName: '',
    lastName: '',
  })

  const vehicleInsuranceForm = useForm<TVehicleInsuranceFormType>({
    mode: 'onChange',
    shouldUseNativeValidation: false,
  })

  const { isDesktop } = useDeviceType()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { name, type: vehicleType } = useVehicleType()

  const { vehicleNumber } = useAppSelector((state) => state.homePage)
  const { vahanData } = useAppSelector((state) => state.vehicleDetails)

  // vahan query
  const [vahanCheck] = useVahanPrefillMutation()

  // make
  const [getManufacturers] = useLazyGetManufacturersQuery()

  // model
  const [getModels] = useLazyGetModelsQuery()

  // variant
  const [getVariant] = useLazyGetVariantsQuery()

  // insurers
  const { data: insurersDropdownData, isFetching: isInsurersDataLoading } = useGetInsurersQuery({
    insurer_type: 'motor',
  })

  const [saveLeads, { isLoading, isError }] = useSaveLeadsMutation()
  const [checkCpa, { isLoading: isCpaCheckLoading, isError: isCpaCheckError }] =
    useCheckForCpaMutation()
  const [closeLead, { isLoading: isCloseLeadLoading, isError: isCloseLeadError }] =
    useCloseLeadMutation()

  // also insurers ;D
  const [getInsurers] = useLazyGetInsurersQuery()

  const [vahanApiSuccess, setVahanApiSuccess] = useState(false)

  const { proposerDetailsFromCBS } = useAppSelector((state) => state.customerDetails)

  // useEffect(() => {
  //   if (cbsName.fetched === '1' && vahanName.fetched === '1') {
  //     const cbsNameArr = Object.values(cbsName)
  //     const vahanNameArr = Object.values(vahanName)
  //     console.log(cbsNameArr)
  //     console.log(vahanNameArr)
  //     const unionArr = union(cbsNameArr, vahanNameArr)
  //       .filter((item) => item !== '')
  //       .filter((item) => item !== '1')
  //     // .filter((item) => {
  //     //   return item !== 'true' || item !== 'false'
  //     // })
  //     console.log(unionArr)

  //     if (unionArr.length > 0) {
  //       console.log('vahan name matched with cbs name (partially or fully)')
  //       ToastService.showInfoToast({
  //         title: 'Vahan name matched with Bank name (partially or fully)',
  //       })
  //       dispatch(updateShowConsent(true))
  //     } else if (!quotationDetails.isIndividual) {
  //       ToastService.showInfoToast({
  //         title: 'Allowing for company case',
  //       })
  //       dispatch(updateShowConsent(true))
  //     } else {
  //       onBlockJourneyOpen()
  //     }
  //   }
  // }, [cbsName, vahanName])

  useEffect(() => {
    ;(async () => {
      try {
        const vahanDetails = await vahanCheck({
          registration_no: vehicleNumber,
          category: vehicleType,
        }).unwrap()
        dispatch(updateVahanDetails(vahanDetails))
        if (vahanDetails) {
          setVahanApiSuccess(true)

          if (vahanDetails?.previous_insurer_code) {
            const { data: insurerData } = await getInsurers({ insurer_type: 'motor' })

            const insurer = insurerData?.find(
              (insurer) =>
                insurer.code.toString() === vahanDetails.previous_insurer_code?.toString(),
            )
            if (insurer?.name && insurer?.code) {
              dispatch(
                updateVehicleInsuranceForm({
                  previous_insurer: {
                    code: insurer.code,
                    name: insurer.name,
                  },
                }),
              )
            }
          }

          const { data: manufacturersDropdownData } = await getManufacturers({
            type: vehicleType.toString(),
          })

          if (vahanDetails.make) {
            const selectedManufacturer = manufacturersDropdownData?.find(
              (manufacturer) => manufacturer.code.toString() === vahanDetails.make?.toString(),
            )
            if (selectedManufacturer) {
              dispatch(updateManufacturer(selectedManufacturer))

              const { data: modelDropdownData } = await getModels({
                manufacturer: selectedManufacturer.code.toString(),
              })
              const selectedModel = modelDropdownData?.find(
                (model) => model.code.toString() === vahanDetails.model?.toString(),
              )
              if (selectedModel) {
                dispatch(updateModel(selectedModel))

                const { data: variantDropdownData } = await getVariant({
                  model: selectedModel.code.toString(),
                })
                const selectedVariant = variantDropdownData?.find(
                  (variant) => variant.code.toString() === vahanDetails.variant?.toString(),
                )
                selectedVariant && dispatch(updateVariant(selectedVariant))
              }
            }
          }

          vahanDetails.registration_year &&
            dispatch(updateManufacturingYear(vahanDetails.registration_year))

          vahanDetails.registration_date &&
            dispatch(
              updateRegistrationDate(
                format(new Date(vahanDetails.registration_date), 'dd/MM/yyyy'),
              ),
            )

          if (vahanDetails.previous_policy_expiry) {
            const previousPolicyExpFromVahan = new Date(vahanDetails.previous_policy_expiry)
            const maxDate = addDays(new Date(), 90)
            if (differenceInDays(maxDate, previousPolicyExpFromVahan) > 0) {
              dispatch(
                updateVehicleInsuranceForm({
                  previous_policy_end_date: format(
                    new Date(vahanDetails.previous_policy_expiry),
                    'dd/MM/yyyy',
                  ),
                }),
              )
            }
          }
          if (vahanDetails.engine_no) {
            dispatch(
              updateProposalDetails({
                engineNumber: vahanDetails.engine_no,
              }),
            )
          }
          if (vahanDetails.chassis_no) {
            dispatch(
              updateProposalDetails({
                chassisNumber: vahanDetails.chassis_no,
              }),
            )
          }
          if (vahanDetails.previous_policy_number) {
            dispatch(
              updateProposalDetails({
                previousPolicyNumber: vahanDetails.previous_policy_number,
              }),
            )
          }

          // if (vahanDetails.owner_name && vahanDetails.owner_name !== '') {
          //   // setVahanName(vahanDetails.owner_name)
          //   const nameArr = vahanDetails.owner_name.split(' ')
          //   let firstName = nameArr[0].replaceAll('.', ' ')
          //   let middleName = ''
          //   let lastName = ''
          //   if (nameArr.length === 2) {
          //     lastName = nameArr[1].replaceAll('.', ' ')
          //   } else if (nameArr.length === 3) {
          //     middleName = nameArr[1].replaceAll('.', ' ')
          //     lastName = nameArr[2].replaceAll('.', ' ')
          //   } else {
          //     lastName = nameArr[nameArr.length - 1].replaceAll('.', ' ')
          //     for (let x = 1; x < nameArr.length - 1; x++) {
          //       middleName = middleName + nameArr[x] + ' '
          //     }
          //     middleName = middleName.trim().replaceAll('.', ' ')
          //     let t
          //     do {
          //       t = middleName.length
          //       if (t > 30) {
          //         const nameArr_temp = middleName.split(' ')
          //         const nameArr_length = nameArr_temp.length
          //         let middleName_temp = ''
          //         if (lastName.length < 30) {
          //           lastName = nameArr_temp[nameArr_length - 1] + ' ' + lastName
          //           for (let x = 0; x < nameArr_length - 1; x++) {
          //             middleName_temp = middleName_temp + nameArr_temp[x] + ' '
          //           }
          //         } else if (firstName.length < 30) {
          //           firstName = firstName + ' ' + nameArr_temp[0]
          //           for (let x = 1; x < nameArr_length; x++) {
          //             middleName_temp = middleName_temp + nameArr_temp[x] + ' '
          //           }
          //         }
          //         middleName = middleName_temp.trim().replaceAll('.', ' ')
          //       }
          //     } while (t > 30)
          //   }

          //   setVahanName({
          //     fetched: '1',
          //     firstName: firstName ? firstName.toLowerCase() : '',
          //     middleName: middleName ? middleName.toLowerCase() : '',
          //     lastName: lastName ? lastName.toLowerCase() : '',
          //   })
          // }

          // if (proposerDetailsFromCBS) {
          //   const customerData = proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp
          //   if (customerData && customerData.length > 0) {
          //     const firstNameFromCbs = customerData[0].frsNm.replaceAll('.', ' ')
          //     const middleNameFromCbs = customerData[0].mdlNm.replaceAll('.', ' ')
          //     const lastNameFromCbs = customerData[0].lstNm.replaceAll('.', ' ')
          //     setCbsName({
          //       fetched: '1',
          //       firstName: firstNameFromCbs ? firstNameFromCbs.toLowerCase() : '',
          //       middleName: middleNameFromCbs ? middleNameFromCbs.toLowerCase() : '',
          //       lastName: lastNameFromCbs ? lastNameFromCbs.toLowerCase() : '',
          //     })
          //     // setCbsName(firstNameFromCbs + ' ' + lastNameFromCbs)
          //     // if (
          //     //   firstName.toLowerCase() === firstNameFromCbs.toLowerCase() ||
          //     //   firstName.toLowerCase() === lastNameFromCbs.toLowerCase() ||
          //     //   lastName.toLowerCase() === firstNameFromCbs.toLowerCase() ||
          //     //   lastName.toLowerCase() === lastNameFromCbs.toLowerCase()
          //     // ) {
          //     //   // Continue jouney - #TODO - add consent for name and store that in db
          //     //   console.log('vahan name matched with cbs name (partially or fully)')
          //     // } else {
          //     //   // Block journey
          //     //   onBlockJourneyOpen()
          //     // }
          //     dispatch(
          //       updateProposalDetails({
          //         firstName: firstNameFromCbs,
          //         middleName: middleNameFromCbs,
          //         lastName: lastNameFromCbs,
          //       }),
          //     )
          //     if (firstNameFromCbs)
          //       dispatch(updateFieldsFetchedFromVahanMapping('customerFirstName'))
          //     if (middleNameFromCbs)
          //       dispatch(updateFieldsFetchedFromVahanMapping('customerMiddleName'))
          //     if (lastNameFromCbs) dispatch(updateFieldsFetchedFromVahanMapping('customerLastName'))
          //   }
          // }

          // if (vahanDetails?.owner_mobile) {
          //   dispatch(
          //     updateProposalDetails({
          //       mobileNumber: vahanDetails.owner_mobile,
          //     }),
          //   )
          // }

          // if (vahanDetails.owner_email) {
          //   dispatch(
          //     updateProposalDetails({
          //       mobileNumber: vahanDetails.owner_email,
          //     }),
          //   )
          // }

          // if (vahanDetails.owner_address) {
          //   const addressArr = vahanDetails.owner_address.split(',')
          //   const firstAddress = addressArr[0]
          //   let secondAddress = ''
          //   for (let i = 1; i < addressArr.length; i++) {
          //     secondAddress += addressArr[i] + ','
          //   }
          //   secondAddress = secondAddress.slice(0, -1)

          //   dispatch(
          //     updateProposalDetails({
          //       address1: firstAddress,
          //       address2: secondAddress,
          //     }),
          //   )
          // }

          if (vahanDetails.on_finanace === true) {
            dispatch(
              updateProposalDetails({
                isLoan: true,
              }),
            )
          }

          // if (vahanDetails.father_name) {
          //   const fatherNameArr = vahanDetails.father_name.split(' ')

          //   const firstName = fatherNameArr[0]
          //   let middleName = ''
          //   let lastname = ''

          //   if (fatherNameArr.length === 2) {
          //     lastname = fatherNameArr[1]
          //   } else if (fatherNameArr.length === 3) {
          //     middleName = fatherNameArr[1]
          //     lastname = fatherNameArr[2]
          //   }

          //   dispatch(
          //     updateProposalDetails({
          //       nomineeRelationship: 'Father',
          //       nomineeFirstName: firstName,
          //       nomineeMiddleName: middleName,
          //       nomineeLastName: lastname,
          //     }),
          //   )
          // }
        }
        if (proposerDetailsFromCBS) {
          if (
            proposerDetailsFromCBS?.IndividualCustomerDetails &&
            proposerDetailsFromCBS.IndividualCustomerDetails.IndCustDtlsRp &&
            quotationDetails.isIndividual
          ) {
            if (proposerDetailsFromCBS.IndividualCustomerDetails.IndCustDtlsRp.length > 0) {
              const customerData = proposerDetailsFromCBS.IndividualCustomerDetails.IndCustDtlsRp[0]
              if (customerData?.frsNm) {
                dispatch(
                  updateProposalDetails({
                    firstName: customerData?.frsNm,
                  }),
                )
                dispatch(updateFieldsFetchedFromCbsMapping('customerFirstName'))
              }
              if (customerData?.mdlNm) {
                dispatch(
                  updateProposalDetails({
                    middleName: customerData?.mdlNm,
                  }),
                )
                dispatch(updateFieldsFetchedFromCbsMapping('customerMiddleName'))
              }
              if (customerData?.lstNm) {
                dispatch(
                  updateProposalDetails({
                    lastName: customerData?.lstNm,
                  }),
                )
                dispatch(updateFieldsFetchedFromCbsMapping('customerLastName'))
              }
              if (customerData?.mblNb) {
                let phoneNum = customerData.mblNb
                if (phoneNum.length === 12) {
                  phoneNum = phoneNum.substring(2)
                }
                dispatch(
                  updateProposalDetails({
                    mobileNumber: phoneNum,
                  }),
                )
                dispatch(updateFieldsFetchedFromCbsMapping('customerMobile'))
              }
              if (customerData?.email) {
                dispatch(
                  updateProposalDetails({
                    email: customerData?.email,
                  }),
                )
                dispatch(updateFieldsFetchedFromCbsMapping('customerEmail'))
              }
            }
          }

          if (
            proposerDetailsFromCBS?.CustmrFlds &&
            proposerDetailsFromCBS.CustmrFlds.CustmrFldsRp &&
            quotationDetails.isIndividual
          ) {
            const customerData = proposerDetailsFromCBS.CustmrFlds.CustmrFldsRp
            // if (customerData.prmntMblNb) {
            //   let phoneNum = customerData.prmntMblNb
            //   if (phoneNum.length === 12) {
            //     phoneNum = phoneNum.substring(2)
            //   }
            //   dispatch(
            //     updateProposalDetails({
            //       mobileNumber: phoneNum,
            //     }),
            //   )
            //   dispatch(updateFieldsFetchedFromCbsMapping('customerMobile'))
            // }

            if (customerData.dob) {
              const date = new Date(customerData.dob)
              if (isValid(date)) {
                dispatch(
                  updateProposalDetails({
                    dob: format(date, 'dd/MM/yyyy'),
                  }),
                )
                dispatch(updateFieldsFetchedFromCbsMapping('customerDob'))
              }
            }

            if (customerData.gndr) {
              if (customerData.gndr === 'M') {
                dispatch(
                  updateProposalDetails({
                    salutation: 'Mr.',
                    isGenderMale: true,
                  }),
                )
              } else if (customerData.gndr === 'F') {
                dispatch(
                  updateProposalDetails({
                    salutation: 'Ms.',
                    isGenderMale: false,
                  }),
                )
              }
            }

            // if (customerData.fathrNm) {
            //   const fatherNameArr = customerData.fathrNm.split(' ')

            //   const firstName = fatherNameArr[0]
            //   let middleName = ''
            //   let lastname = ''

            //   if (fatherNameArr.length === 2) {
            //     lastname = fatherNameArr[1]
            //   } else if (fatherNameArr.length === 3) {
            //     middleName = fatherNameArr[1]
            //     lastname = fatherNameArr[2]
            //   }

            //   dispatch(
            //     updateProposalDetails({
            //       nomineeRelationship: 'Father',
            //       nomineeFirstName: firstName,
            //       nomineeMiddleName: middleName,
            //       nomineeLastName: lastname,
            //     }),
            //   )
            // }

            const address1 = customerData.prmntAddrLn1 ?? ''
            const address2 =
              (customerData.prmntAddrLn2 ?? '') + ' ' + (customerData.prmntAddrLn3 ?? '')
            dispatch(
              updateProposalDetails({
                address1: address1,
                address2: address2,
              }),
            )
            if (address1) dispatch(updateFieldsFetchedFromCbsMapping('customerAddress1'))
            if (address2) dispatch(updateFieldsFetchedFromCbsMapping('customerAddress2'))
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        if (proposerDetailsFromCBS) {
          if (
            proposerDetailsFromCBS?.IndividualCustomerDetails &&
            proposerDetailsFromCBS.IndividualCustomerDetails.IndCustDtlsRp &&
            quotationDetails.isIndividual
          ) {
            if (proposerDetailsFromCBS.IndividualCustomerDetails.IndCustDtlsRp.length > 0) {
              const customerData = proposerDetailsFromCBS.IndividualCustomerDetails.IndCustDtlsRp[0]
              if (customerData?.frsNm) {
                // dispatch(
                //   updateProposalDetails({
                //     firstName: customerData?.frsNm,
                //   }),
                // )
                dispatch(updateFieldsFetchedFromCbsMapping('customerFirstName'))
              }
              if (customerData?.mdlNm) {
                // dispatch(
                //   updateProposalDetails({
                //     middleName: customerData?.mdlNm,
                //   }),
                // )
                dispatch(updateFieldsFetchedFromCbsMapping('customerMiddleName'))
              }
              if (customerData?.lstNm) {
                // dispatch(
                //   updateProposalDetails({
                //     lastName: customerData?.lstNm,
                //   }),
                // )
                dispatch(updateFieldsFetchedFromCbsMapping('customerLastName'))
              }
              if (customerData?.mblNb) {
                let phoneNum = customerData.mblNb
                if (phoneNum.length === 12) {
                  phoneNum = phoneNum.substring(2)
                }
                dispatch(
                  updateProposalDetails({
                    mobileNumber: phoneNum,
                  }),
                )
                dispatch(updateFieldsFetchedFromCbsMapping('customerMobile'))
              }
              if (customerData?.email) {
                dispatch(
                  updateProposalDetails({
                    email: customerData?.email,
                  }),
                )
                dispatch(updateFieldsFetchedFromCbsMapping('customerEmail'))
              }
            }
          }

          if (
            proposerDetailsFromCBS?.CustmrFlds &&
            proposerDetailsFromCBS.CustmrFlds.CustmrFldsRp &&
            quotationDetails.isIndividual
          ) {
            const customerData = proposerDetailsFromCBS.CustmrFlds.CustmrFldsRp
            // if (customerData.prmntMblNb) {
            //   let phoneNum = customerData.prmntMblNb
            //   if (phoneNum.length === 12) {
            //     phoneNum = phoneNum.substring(2)
            //   }
            //   dispatch(
            //     updateProposalDetails({
            //       mobileNumber: phoneNum,
            //     }),
            //   )
            //   dispatch(updateFieldsFetchedFromCbsMapping('customerMobile'))
            // }

            if (customerData.dob) {
              const date = new Date(customerData.dob)
              if (isValid(date)) {
                dispatch(
                  updateProposalDetails({
                    dob: format(date, 'dd/MM/yyyy'),
                  }),
                )
                dispatch(updateFieldsFetchedFromCbsMapping('customerDob'))
              }
            }

            if (customerData.gndr) {
              if (customerData.gndr === 'M') {
                dispatch(
                  updateProposalDetails({
                    salutation: 'Mr.',
                    isGenderMale: true,
                  }),
                )
              } else if (customerData.gndr === 'F') {
                dispatch(
                  updateProposalDetails({
                    salutation: 'Ms.',
                    isGenderMale: false,
                  }),
                )
              }
            }

            // if (customerData.fathrNm) {
            //   const fatherNameArr = customerData.fathrNm.split(' ')

            //   const firstName = fatherNameArr[0]
            //   let middleName = ''
            //   let lastname = ''

            //   if (fatherNameArr.length === 2) {
            //     lastname = fatherNameArr[1]
            //   } else if (fatherNameArr.length === 3) {
            //     middleName = fatherNameArr[1]
            //     lastname = fatherNameArr[2]
            //   }

            //   dispatch(
            //     updateProposalDetails({
            //       nomineeRelationship: 'Father',
            //       nomineeFirstName: firstName,
            //       nomineeMiddleName: middleName,
            //       nomineeLastName: lastname,
            //     }),
            //   )
            // }

            const address1 = customerData.prmntAddrLn1 ?? ''
            const address2 =
              (customerData.prmntAddrLn2 ?? '') + ' ' + (customerData.prmntAddrLn3 ?? '')
            dispatch(
              updateProposalDetails({
                address1: address1,
                address2: address2,
              }),
            )
            if (address1) dispatch(updateFieldsFetchedFromCbsMapping('customerAddress1'))
            if (address2) dispatch(updateFieldsFetchedFromCbsMapping('customerAddress2'))
          }
        }
      }
    })()
  }, [])

  const equitasDetails = useAppSelector((state) => state.customerDetails)
  const homePageDetails = useAppSelector((state) => state.homePage)
  const vehicleDetails = useAppSelector((state) => state.vehicleDetails.vehicleDetails)
  const insuranceDetails = useAppSelector((state) => state.vehicleDetails.vehicleInsuranceDetails)
  const quotationDetails = useAppSelector((state) => state.quotationsDetails)
  const proposalDetails = useAppSelector((state) => state.proposalPage)
  const leadDetails = useAppSelector((state) => state.leadDetails)
  const spTaggingDetails = useAppSelector((state) => state.spTagging)

  useEffect(() => {
    if (leadDetails.lead_uid && isBlockJourneyOpen) {
      createLead()
      // console.log('Lead Details')
      // console.log(leadDetails)
      if (leadDetails.drop_off?.toLowerCase() === 'dropped name mismatch') closeBlockedLead()
    }
  }, [isBlockJourneyOpen, leadDetails.drop_off])

  const closeBlockedLead = async () => {
    try {
      if (leadDetails.drop_off?.toLowerCase() === 'dropped name mismatch') {
        const response = await closeLead({
          lead_uid: leadDetails.lead_uid ?? '',
          lead_close_reason: 'Name Mismatch',
        }).unwrap()
      }
      // ToastService.showSuccessTaost({
      //   title: 'Success',
      //   message: 'Lead Closed Successfully',
      // })
    } catch (e) {
      ToastService.showErrorToast({
        title: 'Error',
        message: 'Cannot close the lead',
      })
      // console.log(e)
    }
  }

  const formParam = (drop_off: string, product_name: string) => {
    const firstName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].frsNm
    const lastName =
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp &&
      equitasDetails.proposerDetailsFromCBS?.IndividualCustomerDetails.IndCustDtlsRp[0].lstNm
    const payload: ISaveLead = {
      lead_uid: leadDetails.lead_uid,
      ucic: equitasDetails.ucic?.toString() ?? '',
      vehicle_number: homePageDetails.vehicleNumber,
      // cx_firstname: equitasDetails.proposerDetailsFromCBS?.first_name ?? '',
      // cx_lastname: equitasDetails.proposerDetailsFromCBS?.last_name ?? '-',
      cx_firstname: firstName ?? '',
      cx_lastname: lastName ?? '',
      drop_off: isBlockJourneyOpen ? 'Dropped Name Mismatch' : drop_off,
      product_name: product_name,
      // insurer_name: insuranceDetails.previousInsurer?.name,
      lead_data: {
        homepage_details: homePageDetails,
        vehicle_details: vehicleDetails,
        insurance_details: insuranceDetails,
        quotation_details: quotationDetails,
        proposal_details: proposalDetails,
        equitas_details: equitasDetails,
        lead_details: leadDetails,
        spTagging_details: spTaggingDetails,
      },
    }
    if (!payload.lead_uid) {
      delete payload.lead_uid
    }
    return payload
  }

  useEffect(() => {
    if (leadDetails.lead_uid) {
      try {
        createLead()
      } catch (e) {
        console.log(e)
      }
    }
  }, [leadDetails, insuranceDetails, vehicleDetails])

  useEffect(() => {
    if (homePageDetails.vehicleNumber) {
      checkCpa({ rto: homePageDetails.vehicleNumber.substring(0, 5).replaceAll('-', '') })
    }
  }, [homePageDetails])

  // useEffect(() => {
  //   try {
  //     createLead()
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }, [insuranceDetails, vehicleDetails])

  const createLead = async () => {
    const payload: ISaveLead = formParam('PreQuote', homePageDetails.selectedSubType)
    try {
      const response = await saveLeads(payload).unwrap()
      const response2: ISaveLeadResponse = JSON.parse(decryptData(response.data ?? ''))
      dispatch(updateLeadID({ lead_uid: response2.lead_uid }))
      dispatch(updateDropOff({ drop_off: response2.drop_off }))
    } catch (e) {
      console.log(e)
    }
    // return response
  }

  return (
    <>
      <Header />
      <Box marginX={['5', '5', '7', '10', '20', '20']} marginY='5'>
        <Flex justifyContent='space-between' alignItems='center'>
          <HeadingText label={`Verify ${name} Insurance`} />
          <ElevatedButton
            // leftIcon={<MdBuild />}
            colorScheme='secondary'
            variant='outline'
            onClick={() => {
              copyToClipboard(leadDetails.lead_uid ?? '')
              // dispatch(updateVehicleInsuranceForm(vehicleInsuranceForm.watch()))
              // ToastService.showSuccessTaost({
              //   title: 'Success',
              //   message: 'Lead Saved Successfully',
              // })
            }}
          >
            {leadDetails.lead_uid ?? '-'}
          </ElevatedButton>
        </Flex>
        {vahanApiSuccess && (
          <Text paddingY='1' fontSize='sm' color='grey'>
            The following details have been pre-fetched using your registration number. You can edit
            details incase any information is incorrect.
          </Text>
        )}
        {isDesktop ? (
          <VehicleInsuranceDetailsDesktop form={vehicleInsuranceForm} />
        ) : (
          <VehicleInsuranceDetailsMobile form={vehicleInsuranceForm} />
        )}
      </Box>
      <Modal
        onCloseComplete={() => {
          navigate('/customer-account')
        }}
        isCentered
        isOpen={isBlockJourneyOpen}
        onClose={onBlockJourneyClose}
      >
        <ModalOverlay />
        <ModalContent>
          <JourneyBlockDialog>
            <Flex direction='column' alignItems='center'>
              <Text>
                Name Fetched From Vahan -{' '}
                {`${vahanName.firstName ?? ''} ${vahanName.middleName ?? ''} ${
                  vahanName.lastName ?? ''
                }`}
              </Text>
              <Text>
                Name Fetched From CBS -{' '}
                {`${cbsName.firstName ?? ''} ${cbsName.middleName ?? ''} ${cbsName.lastName ?? ''}`}
              </Text>
            </Flex>
          </JourneyBlockDialog>
        </ModalContent>
      </Modal>
    </>
  )
}

export { VehicleInsuranceDetails }
