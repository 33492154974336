import { BoxProps } from '@chakra-ui/react'
import { store } from 'app/store'
import axios, { AxiosResponse } from 'axios'
import { dotNotInclude, PRODUCT_URL } from 'Constants'
import { ElementWithLabel, ErrorText, TextInput } from 'Core'
import { INestedQuestionSet } from 'Models/equitas/life/questionSet'
import { IApiResponse2 } from 'Models/ResponseModels'
import { useEffect, useState } from 'react'
import { UseFormReturn, ValidationRule } from 'react-hook-form'
import { getDefaultHeaders2 } from 'Services/baseQuery'
import { convertNumberToWords } from 'Services/general'
import { objectEntries, objectKeys } from 'ts-extras'

const updateValueUsingDependentFields = ({
  type,
  questionCode,
  value,
  form,
}: {
  type: 'text' | 'date'
  questionCode: string
  value: Record<string, string | number>
  form: UseFormReturn<any, any>
}) => {
  const valueInString = objectKeys(value)[0]

  switch (type) {
    case 'text':
      form.setValue(questionCode, valueInString)
      break
    case 'date': // TODO: Add date parsing before setting value in the form on the basis of data recieved from backend
      form.setValue(questionCode, valueInString)
      break
    default:
      break
  }
}

interface IProps extends BoxProps {
  isRoot: boolean
  isRequiredField?: boolean
  isDisabled?: boolean
  parentData: {
    name: string
    value: string | number
    cover_type?: INestedQuestionSet['cover_type']
  }
  name: string
  placeholder: string
  label?: string
  min?: number | null
  max?: number | null
  regex?: string | RegExp
  input_type?: string
  initialValues?: Record<string, string | number | boolean | string[]>
  //   childrenData?: Record<string, INestedQuestionSet[]>
  form: UseFormReturn<any, any>
  factor?: number
  operation?: string
  value?: string
  isWithoutLabel?: boolean
  defaultValue?: string
  disabledCodes?: string[]
  hidden?: boolean
  affect?: string
  onAffect?: (
    name: string,
    value: string | number,
    affect: string,
    factor?: number,
    operation?: string,
  ) => void
  required?: boolean
  call_api?: boolean
}

// TODO: Add input type - text/number restriction here
const RenderTextInput = (props: IProps) => {
  const {
    name,
    placeholder,
    //  childrenData,
    form,
    isRoot,
    parentData,
    isRequiredField,
    isDisabled,
    label,
    min,
    max,
    regex,
    input_type,
    initialValues,
    factor,
    operation,
    value,
    isWithoutLabel,
    defaultValue,
    disabledCodes,
    affect,
    onAffect,
    hidden,
    required,
    call_api,
    ...rest
  } = props

  const [minVal, setMinVal] = useState(min)
  const [maxVal, setMaxVal] = useState(max)
  const [regexVal, setRegexVal] = useState(regex)

  const [customError, setCustomError] = useState('')
  const [otherRequiredFieldsError, setOtherRequiredFieldsError] = useState(false)

  useEffect(() => {
    if (initialValues && initialValues[name]) {
      form.setValue(name, initialValues[name])
    }
  }, [initialValues])

  useEffect(() => {
    setMinVal(min)
  }, [min])

  useEffect(() => {
    setMaxVal(max)
  }, [max])

  useEffect(() => {
    setRegexVal(regex)
  }, [regex])

  useEffect(() => {
    if (isMinMaxEqual()) {
      form.setValue(name, minVal)
    }
  }, [minVal, maxVal, parentData.value])

  const minValidationFn = (value: any) => {
    let valid = true
    if (input_type) {
      const minTemp = minVal as number
      switch (input_type.toLowerCase()) {
        case 'numeric':
          if (minTemp >= 0) {
            valid = Number(value) >= minTemp
          }
          break
        case 'string':
          if (minTemp >= 0) {
            valid = value.length >= minTemp
          }
          break
        default:
          break
      }
    }
    return valid
  }

  const maxValidationFn = (value: any) => {
    let valid = true
    if (input_type) {
      const maxTemp = maxVal as number
      switch (input_type.toLowerCase()) {
        case 'numeric':
          if (maxTemp) {
            valid = Number(value) <= maxTemp
          }
          break
        case 'string':
          if (maxTemp) {
            valid = value.length <= maxTemp
          }
          break
        default:
          break
      }
    }
    return valid
  }

  const customErrorFn = () => {
    let valid = true
    if (customError !== '') {
      valid = false
    }
    return valid
  }

  const otherRequiredFieldsErrorFn = () => {
    let valid = true
    if (otherRequiredFieldsError) {
      valid = true
    }
    return valid
  }

  const getIsDisabled = () => {
    return (
      isDisabled ||
      (disabledCodes && disabledCodes.length ? disabledCodes.includes(name) : false) ||
      isMinMaxEqual()
    )
  }
  const isMinMaxEqual = () => {
    return (
      minVal !== null &&
      maxVal !== null &&
      minVal !== undefined &&
      maxVal !== undefined &&
      minVal === maxVal &&
      minVal >= 0 &&
      maxVal >= 0 &&
      Number(defaultValue) == minVal
    )
  }

  useEffect(() => {
    if (form.getValues(name) && affect && onAffect) {
      onAffect(name, form.getValues(name), affect, factor, operation)
    }
  }, [form.getValues(name)])

  const getValidationData = async () => {
    if (call_api) {
      const insurer = store.getState().lifeQuotationDetails.finalSelectedPlan?.insurer
      const insurerProductCode = store.getState().lifeQuotationDetails.finalSelectedPlan?.code
      try {
        const resp: AxiosResponse<
          IApiResponse2<{
            field_validation: {
              min: string | number
              max: string | number
              regex: string
              req_fields?: string[]
              error_msg?: string
            }
            dependent_fields?: Record<
              string,
              {
                type: 'text' | 'date'
                values: Record<string, string | number>
              }
            >
            default: string
            is_disabled: boolean
          }>
        > = await axios({
          url: `${PRODUCT_URL}/product/get-choices/${insurer}/${insurerProductCode}/${name}/`,
          method: 'POST',
          data: {
            ...form.getValues(),
          },
          headers: getDefaultHeaders2({
            token: store.getState().user.authenticatedUser.authtoken ?? '',
          }),
        })

        const dependentFields = resp.data.data?.dependent_fields

        if (dependentFields) {
          const dataInEntries = objectEntries(dependentFields)
          dataInEntries.forEach((item) => {
            const questionCode = item[0]
            const questionType = item[1].type
            const newQuestionValue = item[1].values
            updateValueUsingDependentFields({
              form,
              type: questionType,
              questionCode,
              value: newQuestionValue,
            })
          })
        }

        setCustomError('')

        const fieldValidationData = resp.data.data?.field_validation

        if (fieldValidationData) {
          // If `fieldValidationData?.req_fields` has some value, then set custom validation to this field and return
          if (fieldValidationData?.req_fields && fieldValidationData?.req_fields?.length > 0) {
            form.setError(name, {
              type: 'otherRequiredFields',
              message: fieldValidationData?.error_msg ?? 'Some Other fields are required!',
            })
            setOtherRequiredFieldsError(true)
            // setCustomError(fieldValidationData?.error_msg)
            // await form.trigger(name)
            // return
          } else {
            setOtherRequiredFieldsError(false)
          }

          if (fieldValidationData?.error_msg) {
            setCustomError(fieldValidationData?.error_msg)
            // console.log('setting custom error!')

            // await form.trigger(name)
          }
          // else {
          //   // await form.trigger(name)
          //   // console.log('removing custom error!')

          //   setCustomError('')
          // }

          if (fieldValidationData.min?.toString()) {
            setMinVal(parseInt(fieldValidationData.min?.toString()))
          }

          if (fieldValidationData.max?.toString()) {
            setMaxVal(parseInt(fieldValidationData.max?.toString()))
          }

          if (fieldValidationData.regex?.toString()) {
            setRegexVal(fieldValidationData.regex?.toString())
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  return (
    <>
      <ElementWithLabel
        marginY='0.7rem'
        required={required}
        height='fit-content'
        label={isWithoutLabel ? '' : label || name}
        {...rest}
      >
        <TextInput
          placeholder={`Please enter ${label}.`}
          defaultValue={defaultValue}
          isDisabled={getIsDisabled()}
          onFocus={getValidationData}
          {...form.register(name, {
            required: required,
            validate: {
              min: minValidationFn,
              max: maxValidationFn,
              custom: customErrorFn,
              otherRequiredFields: otherRequiredFieldsErrorFn,
            },
            pattern: regexVal
              ? new RegExp(regexVal.toString().slice(1, -1))
              : input_type && input_type.toLowerCase() === 'numeric'
              ? new RegExp(dotNotInclude)
              : undefined,
            onBlur: () => {
              getValidationData()
            },
          })}
          type={input_type && input_type.toLowerCase() === 'numeric' ? 'number' : 'text'}
          value={value}
          // onKeyDown={(e) => {
          //   return getIsCustomValidationError() ? e.preventDefault() : null
          // }}
          onKeyDown={(e) => {
            return otherRequiredFieldsError ? e.preventDefault() : null
          }}
        />
        {input_type && form.formState.errors[name] ? (
          <>
            {/* {form.formState.errors[name]?.type === 'custom' ? (
              <ErrorText
                text={
                  form.formState.errors[name]?.message?.toString() ?? 'Other fields are required!'
                }
              />
            ) : null} */}
            {form.formState.errors[name]?.type === 'required' ? (
              <ErrorText text={`${label || name} is required!`} />
            ) : null}
            {form.formState.errors[name]?.type === 'pattern' ? (
              <ErrorText text={`${label || name} is Invalid!`} />
            ) : null}
            {input_type.toLowerCase() === 'numeric' ? (
              <>
                {form.formState.errors[name]?.type === 'min'
                  ? minVal && (
                      <ErrorText
                        text={`Min value: ${minVal}${maxVal ? `, Max value: ${maxVal} ` : ''}`}
                      />
                    )
                  : null}
                {form.formState.errors[name]?.type === 'max'
                  ? maxVal && (
                      <ErrorText
                        text={`Max value: ${maxVal}${minVal ? `, Min value: ${minVal} ` : ''}`}
                      />
                    )
                  : null}
                {/* <>
                  {form.formState.errors[name]?.type === 'min' ? (
                    <ErrorText text={`Minimum value: ${min}`} />
                  ) : null}
                  {form.formState.errors[name]?.type === 'max' ? (
                    <ErrorText text={`Maximum value: ${max}`} />
                  ) : null}
                </> */}
              </>
            ) : null}
            {input_type.toLowerCase() === 'string' ? (
              <>
                {form.formState.errors[name]?.type === 'min' ? (
                  <ErrorText text={`Minimum ${minVal} alphabets required!`} />
                ) : null}
                {form.formState.errors[name]?.type === 'max' ? (
                  <ErrorText text={`Maximum ${maxVal} alphabets only!`} />
                ) : null}
              </>
            ) : null}
            {form.formState.errors[name]?.type === 'custom' ? (
              <ErrorText text={customError} />
            ) : null}
            {form.formState.errors[name]?.type === 'otherRequiredFields' ? (
              <ErrorText text={customError} />
            ) : null}
          </>
        ) : null}
      </ElementWithLabel>
    </>
  )
}

export { RenderTextInput }
