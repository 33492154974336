import { createApi } from '@reduxjs/toolkit/query/react'
import { store } from 'app/store'
import { API_ENDPOINTS } from 'Constants'
import {
  updateProposalButtonLoadingStatus,
  updateProposalSuccessResponse,
  updateProposalErrorResponse,
  updatePolicyDataFromOCR,
  toggleProposalScreen,
  updateCpaCheck,
} from 'features/Motor'
import {
  IMotorSalesBankParams,
  IQuotationRequest,
  TMotorProposalPayload,
} from 'Models/RequestModels'
import {
  ICustomerConsentGenerationPayload,
  ICustomerConsentFormPayload,
  ISpConsentGenerationPayload,
  ISpConsentFormPayload,
} from 'Models/RequestModels/Equitas'
import { ILifeSalesBankParams } from 'Models/RequestModels/Life'
import {
  IAdditionalCovers,
  IAddOns,
  IInsurer,
  IManufacturer,
  IModel,
  IPincode,
  IVariant,
  IApiResponse,
  IAuthenticatedUser,
  IGetPollingQuotation,
  IPostPollingQuotation,
  IProposal,
  IPlanDetails,
  IVahan,
  IMLPolicyResponse,
  IPaymentCheck,
  IHypothecation,
  IMLRcResponse,
} from 'Models/ResponseModels'
import { ICustomerConsentStatusCheck, ISpConsentStatusCheck } from 'Models/ResponseModels/Equitas'
import { IMotorSalesReportBank, IMotorSoldPolicySummary } from 'Models/ResponseModels/motor'
import { baseUrlQuery, getFileHeaders } from 'Services/baseQuery'
import { ToastService } from 'Services/toast.service'

export const motorApi = createApi({
  reducerPath: 'motorApi',
  baseQuery: baseUrlQuery,
  tagTypes: ['Make', 'Model', 'Variant'],
  endpoints: (builder) => ({
    getManufacturers: builder.query<IManufacturer[], { type: string }>({
      providesTags: ['Make'],
      query: (vehicleType: { type: string }) => ({
        url: API_ENDPOINTS.MANUFACTURERS,
        method: 'GET',
        params: vehicleType,
      }),
    }),
    getModels: builder.query<IModel[], { manufacturer: string }>({
      providesTags: ['Model'],
      query: (selectedManufacturerCode: { manufacturer: string }) => ({
        url: API_ENDPOINTS.MODELS,
        method: 'GET',
        params: selectedManufacturerCode,
      }),
    }),
    getVariants: builder.query<IVariant[], { model: string }>({
      providesTags: ['Variant'],
      // invalidatesTags: [''],
      query: (selectedModelCode: { model: string }) => ({
        url: API_ENDPOINTS.VARIANTS,
        method: 'GET',
        params: selectedModelCode,
      }),
    }),
    getInsurers: builder.query<IInsurer[], { insurer_type: string }>({
      query: (insurerType: { insurer_type: string }) => ({
        url: API_ENDPOINTS.INSURERS,
        method: 'GET',
        params: insurerType,
      }),
    }),
    getAdditionalCovers: builder.query<IAdditionalCovers, { variable_type: string }>({
      query: (type: { variable_type: string }) => ({
        url: API_ENDPOINTS.ADDITIONAL_COVERS,
        method: 'GET',
        params: type,
      }),
      transformResponse: (response: IAdditionalCovers) => {
        Object.keys(response).map((cover) => {
          response[cover].map((c) => {
            c.isSelected = false
          })
        })
        return response
      },
    }),
    getAddons: builder.query<IAddOns, { variable_type: string }>({
      query: (type: { variable_type: string }) => ({
        url: API_ENDPOINTS.ADD_ONS,
        method: 'GET',
        params: type,
      }),
    }),
    postPollingQuotation: builder.mutation<IPostPollingQuotation, IQuotationRequest>({
      query: (payload: IQuotationRequest) => ({
        url: API_ENDPOINTS.MOTOR_QUOTATIONS,
        method: 'POST',
        body: payload,
      }),
    }),
    getPollingQuotation: builder.query<IGetPollingQuotation, { sub_request_id: string }>({
      query: (type: { sub_request_id: string }) => ({
        url: API_ENDPOINTS.MOTOR_QUOTATIONS,
        method: 'GET',
        params: type,
      }),
    }),
    getPlanDetails: builder.query<IPlanDetails, { insurance_code: string; policy_type: string }>({
      query: (type: { insurance_code: string; policy_type: string }) => ({
        url: API_ENDPOINTS.PLAN_DETAILS,
        method: 'GET',
        params: type,
      }),
    }),
    validateRto: builder.query<{ [key: string]: string }, { keyword: string }>({
      query: (rtoObj: { keyword: string }) => ({
        url: API_ENDPOINTS.RTO,
        method: 'GET',
        params: rtoObj,
      }),
    }),
    getPincode: builder.query<IPincode[], { pincode: number; insurance_id: number }>({
      query: (pincodeParams: { pincode: number; insurance_id: number }) => ({
        url: API_ENDPOINTS.PINCODE_CMS,
        method: 'GET',
        params: pincodeParams,
      }),
    }),
    getOccupations: builder.query<{ [key: number]: string }, void>({
      query: () => ({
        url: API_ENDPOINTS.OCCUPATIONS,
        method: 'GET',
      }),
    }),
    getRelationships: builder.query<{ [key: number]: string }, void>({
      query: () => ({
        url: API_ENDPOINTS.RELATIONSHIPS,
        method: 'GET',
      }),
    }),
    postProposal: builder.mutation<IProposal, TMotorProposalPayload>({
      query: (proposalPayload: TMotorProposalPayload) => ({
        url: API_ENDPOINTS.MOTOR_PROPOSAL,
        method: 'POST',
        body: proposalPayload,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          dispatch(updateProposalButtonLoadingStatus('loading'))
          const { data: proposalResponse } = await queryFulfilled
          console.log(proposalResponse)
          dispatch(updateProposalSuccessResponse(proposalResponse))
          dispatch(updateProposalButtonLoadingStatus('success'))
          // dispatch(toggleProposalScreen('three'))
          console.log(proposalResponse)
        } catch (err: any) {
          console.log('Errrr...', err['error']['message'])
          // dispatch error string of proposal response
          dispatch(updateProposalButtonLoadingStatus('error'))
          dispatch(updateProposalErrorResponse(err['error']['message']))
          ToastService.showErrorToast({
            title: err['error']['message'],
          })
        }
      },
    }),
    paymentCheck: builder.query<IPaymentCheck, { unique_identifier: string }>({
      query: (uniqueIdentifier: { unique_identifier: string }) => ({
        url: API_ENDPOINTS.PAYMENT_CHECK,
        method: 'GET',
        params: uniqueIdentifier,
      }),
    }),
    vahanPrefill: builder.mutation<IVahan, { category: number; registration_no: string }>({
      query: (vahanPayload: { category: number; registration_no: string }) => ({
        url: API_ENDPOINTS.VAHAN_CHECK,
        method: 'POST',
        body: vahanPayload,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          console.log(data)
          // dispatch()
        } catch (e) {
          console.log(e)
        }
      },
    }),
    uploadImageRC: builder.mutation<IMLRcResponse, FormData>({
      query: (filePayload: FormData) => {
        const token: string = store.getState().user.authenticatedUser.authtoken ?? ''
        return {
          url: API_ENDPOINTS.IMAGE_RC_OCR,
          method: 'POST',
          body: filePayload,
          headers: getFileHeaders(token),
        }
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
        } catch (e: any) {
          ToastService.showErrorToast({
            title: 'Error',
            message: e['error']['message'],
          })
        }
      },
    }),
    uploadPolicyPdf: builder.mutation<IMLPolicyResponse, FormData>({
      query: (filePayload: FormData) => {
        const token: string = store.getState().user.authenticatedUser.authtoken ?? ''
        return {
          url: API_ENDPOINTS.IMAGE_POLICY_OCR,
          method: 'POST',
          body: filePayload,
          headers: getFileHeaders(token),
        }
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled
          const { vehicleNumber } = store.getState().homePage
          const registrationNoFilled = vehicleNumber.replaceAll('-', '')
          const registrationNoFromOcr = response.data.registration_number
          console.log('registrationNoFilled - ', registrationNoFilled)
          console.log('registrationNoFromOcr - ', registrationNoFromOcr)
          if (registrationNoFilled.toLowerCase() === registrationNoFromOcr?.toLowerCase()) {
            dispatch(updatePolicyDataFromOCR(response.data))
          } else {
            ToastService.showErrorToast({
              title: 'Registration no. entered does not match with Policy copy uploaded',
              message: 'Please check the details before proceeding further',
            })
          }
        } catch (e: any) {
          ToastService.showErrorToast({
            title: 'Error',
            message: e['error']['message'],
          })
        }
      },
    }),
    getHypothecation: builder.query<
      IHypothecation[],
      { bank_name: string; insurance_id: string | number; category: string }
    >({
      query: (hypothecationParams: {
        bank_name: string
        insurance_id: string | number
        category: string
      }) => ({
        url: API_ENDPOINTS.HYPOTHECATION,
        method: 'GET',
        params: hypothecationParams,
      }),
    }),
    motorSalesBank: builder.query<IMotorSalesReportBank, IMotorSalesBankParams>({
      query: (queryParams) => ({
        url: API_ENDPOINTS.MOTOR_SALES_BANK,
        method: 'GET',
        params: queryParams,
      }),
      keepUnusedDataFor: 0.0001,
    }),
    motorSalesSummaryBank: builder.query<
      IMotorSoldPolicySummary,
      { start_date?: string; end_date?: string }
    >({
      query: (params) => ({
        url: API_ENDPOINTS.MOTOR_SALES_SUMMARY_BANK,
        method: 'GET',
        params: params,
      }),
      keepUnusedDataFor: 0.0001,
    }),
    getCustomerConsentFormLink: builder.mutation<string, ICustomerConsentGenerationPayload>({
      query: (data) => ({
        url: API_ENDPOINTS.GET_MOTOR_CUSTOMER_CONSENT_FORM_LINK,
        method: 'POST',
        body: data,
      }),
    }),
    checkForCustomerConsentSubmissionStatus: builder.query<
      ICustomerConsentStatusCheck,
      {
        unique_identifier: string
      }
    >({
      query: (param) => ({
        url: API_ENDPOINTS.GET_MOTOR_CUSTOMER_CONSENT_FORM_LINK,
        method: 'GET',
        params: param,
        headers: { 'Content-Type': 'application/json', Source: 'Web', Lang: 'EN' },
      }),
    }),
    submitCustomerConsentForm: builder.mutation<string, ICustomerConsentFormPayload>({
      query: (customerData) => ({
        url: API_ENDPOINTS.SUBMIT_MOTOR_CUSTOMER_CONSENT_FORM,
        method: 'POST',
        body: customerData,
        headers: { 'Content-Type': 'application/json', Source: 'Web', Lang: 'EN' },
      }),
    }),
    getSpConsentFormLink: builder.mutation<string, ISpConsentGenerationPayload>({
      query: (spData) => ({
        url: API_ENDPOINTS.GET_SP_CONSENT_FORM_LINK,
        method: 'POST',
        body: spData,
        headers: { 'Content-Type': 'application/json', Source: 'Web', Lang: 'EN' },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
        } catch (e: any) {
          ToastService.showErrorToast({
            title: 'Error',
            message: e['error']['message'],
          })
        }
      },
    }),
    checkForspConsentSubmissionStatus: builder.query<
      ISpConsentStatusCheck,
      {
        unique_identifier: string
      }
    >({
      query: (param) => ({
        url: API_ENDPOINTS.GET_SP_CONSENT_FORM_LINK,
        method: 'GET',
        params: param,
        headers: { 'Content-Type': 'application/json', Source: 'Web', Lang: 'EN' },
      }),
    }),
    submitSpConsentForm: builder.mutation<string, ISpConsentFormPayload>({
      query: (spData) => ({
        url: API_ENDPOINTS.SUBMIT_SP_CONSENT_FORM,
        method: 'POST',
        body: spData,
        headers: { 'Content-Type': 'application/json', Source: 'Web', Lang: 'EN' },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          ToastService.showSuccessTaost({
            title: 'Success',
          })
        } catch (e: any) {
          ToastService.showErrorToast({
            title: 'Error',
            message: e['error']['message'],
          })
        }
      },
    }),
    checkForCpa: builder.mutation<{ Cpa: boolean }, { rto: string }>({
      query: (payload: { rto: string }) => ({
        url: API_ENDPOINTS.CPA_CHECK,
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: cpaResponse } = await queryFulfilled
          dispatch(updateCpaCheck(cpaResponse.Cpa))
        } catch (e: any) {
          console.log(e)
        }
      },
    }),
    consentUpload: builder.mutation<
      { api_response?: string; error_text?: string },
      {
        unique_identifier: string
        consent_type: string
        b64_image: string
        email_id: string
        lead_uid: string
      }
    >({
      query: (data) => ({
        url: API_ENDPOINTS.CONSENT_UPLOAD,
        method: 'POST',
        body: data,
        headers: { 'Content-Type': 'application/json', Source: 'Web', Lang: 'EN' },
      }),
    }),
    getGeoData: builder.query<
      {
        data: string
      },
      {
        city_id: number
        state_id?: number
        district_id?: number
      }
    >({
      query: (param) => ({
        url: API_ENDPOINTS.GET_GEO_DATA,
        method: 'GET',
        params: param,
        // headers: { 'Content-Type': 'application/json', Source: 'Web', Lang: 'EN' },
      }),
    }),
  }),
})

export const {
  useGetManufacturersQuery,
  useLazyGetManufacturersQuery,
  useGetModelsQuery,
  useLazyGetModelsQuery,
  useGetVariantsQuery,
  useLazyGetVariantsQuery,
  useGetAdditionalCoversQuery,
  useGetAddonsQuery,
  useGetInsurersQuery,
  useLazyGetInsurersQuery,
  useGetPollingQuotationQuery,
  usePostPollingQuotationMutation,
  useGetPlanDetailsQuery,
  useLazyValidateRtoQuery,
  useLazyGetPincodeQuery,
  useGetOccupationsQuery,
  useGetRelationshipsQuery,
  usePostProposalMutation,
  useVahanPrefillMutation,
  useUploadImageRCMutation,
  useUploadPolicyPdfMutation,
  useLazyPaymentCheckQuery,
  useLazyGetHypothecationQuery,
  useMotorSalesBankQuery,
  useCheckForCpaMutation,
  useMotorSalesSummaryBankQuery,
  useGetCustomerConsentFormLinkMutation,
  useSubmitCustomerConsentFormMutation,
  useLazyCheckForCustomerConsentSubmissionStatusQuery,
  useGetSpConsentFormLinkMutation,
  useLazyCheckForspConsentSubmissionStatusQuery,
  useSubmitSpConsentFormMutation,
  useConsentUploadMutation,
  useLazyGetGeoDataQuery,
} = motorApi
