import { combineReducers, configureStore } from '@reduxjs/toolkit'
import storageSession from 'redux-persist/lib/storage/session'
import { userReducer } from 'features/Authentication'
import { cartReducer } from 'features/Health/cart.health.slice'
import { memberDetailsReducer } from 'features/Health/memberDetails.health.slice'
import { homePageReducer } from 'features/Home'
import { vehicleDetailsReducer, quotationsReducer, proposalPageReducer } from 'features/Motor'
import { leadDetailsReducer } from 'features/Leads'
import { authenticationApi } from 'Services/API/authentication.api'
import { healthApi } from 'Services/API/health.api'
import { motorApi } from 'Services/API/motor.api'
import persistReducer from 'redux-persist/es/persistReducer'
import { equitasApi } from 'Services/API/equitas.api'
import { leadsApi } from 'Services/API/leads.api'
import { customerDetailsReducer, spTaggingReducer } from 'features/Equitas'
import { encryptTransform } from 'redux-persist-transform-encrypt'
import { lifeCustomerDetailsReducer } from 'features/Life/LifeCustomerDetails.life.slice'
import { lifeApi } from 'Services/API/life.api'
import { planDataReducer } from 'features/Life/PlanData.slice'
// import { lifeQuotationReducer } from 'features/Life/LifeQuotation.slice'
// import { suitabilityDataReducer } from 'features/Life/LifeSutibilityData.life.slice'
// import { lifeRecommendedPlan } from 'features/Life/LifeRecommendedPlan.life.slice'
import { lifeQuotationDetails } from 'features/Life/LifeQuotationDetails.slice'

const persistConfig = {
  key: `STORE_${process.env.REACT_APP_ENV}`,
  storage: storageSession,
  // transforms: <any>[
  //   encryptTransform({
  //     secretKey: 'rock-and-roll',
  //     // onError: (error) => console.log(error),
  //   }),
  // ],
}

const reducers = combineReducers({
  user: userReducer,
  homePage: homePageReducer,
  vehicleDetails: vehicleDetailsReducer,
  quotationsDetails: quotationsReducer,
  proposalPage: proposalPageReducer,
  [authenticationApi.reducerPath]: authenticationApi.reducer,
  memberDetails: memberDetailsReducer,
  cart: cartReducer,
  leadDetails: leadDetailsReducer,
  [healthApi.reducerPath]: healthApi.reducer,
  [motorApi.reducerPath]: motorApi.reducer,
  [equitasApi.reducerPath]: equitasApi.reducer,
  [lifeApi.reducerPath]: lifeApi.reducer,
  [leadsApi.reducerPath]: leadsApi.reducer,
  customerDetails: customerDetailsReducer,
  spTagging: spTaggingReducer,
  lifeCustomerDetails: lifeCustomerDetailsReducer,
  lifeQuotationDetails: lifeQuotationDetails,
  planData: planDataReducer,
})

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (gDM) =>
    gDM({ serializableCheck: false }).concat(
      authenticationApi.middleware,
      motorApi.middleware,
      healthApi.middleware,
      equitasApi.middleware,
      leadsApi.middleware,
      lifeApi.middleware,
    ),
  devTools: true, // process.env.NODE_ENV !== 'production'
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
